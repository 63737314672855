import React from "react";
import { ProvidedAppStore } from "../../../store/AppStore";
import {
    AnnotationCategories,
    AnnotationTypes,
    KeypointAnnotationTypes,
    MediaStore,
    ObjectDetectionAnnotationTypes,
} from "../../../store/MediaStore";
import { inject, observer } from "mobx-react";
import {
    Button,
    Container,
    Header,
    Icon,
    Loader,
    Message,
    Segment,
    SegmentGroup,
} from "semantic-ui-react";

import {
    objectColor,
    ObjectDetectionDescription,
    Instance as ObjectDetectionInstance,
} from "../../../models/model_types/object_detection";
import { AnyInstance, InstanceInfo, Mode } from "../MediaAnnotation";
import {
    KeyPointDescription,
    Instance as KeypointInstance,
} from "../../../models/model_types/keypoints";

interface MediaAnnotationControlsProps {
    currentTimestep: number;
    mode: Mode;
    createInstance: (category: AnnotationCategories, type: AnnotationTypes, metadata: any) => void;
    removeInstance: (
        category: AnnotationCategories,
        type: AnnotationTypes,
        instance: AnyInstance
    ) => void;
    updateInstance: (instanceInfo: InstanceInfo) => void;
    setMode: (mode: Mode) => void;
    focussedInstanceInfo?: InstanceInfo;
}

type Props = ProvidedAppStore & MediaAnnotationControlsProps;

@inject("store")
@observer
export class AnnotationControls extends React.Component<Props, {}> {
    store: MediaStore | undefined;

    constructor(props: Props) {
        super(props);
        this.store = props.store?.mediaStore;
        this.state = {};
    }

    generateObjectDetectionCreateInstanceButtonsJsx() {
        if (!this.store) return <Loader active />;

        const annotationType = this.store.findAnnotationType(
            AnnotationCategories.ObjectDetection,
            ObjectDetectionAnnotationTypes.Generic
        );
        if (!annotationType) return <Loader active />;

        const newInstanceButtonsJsx = (
            annotationType.definition as ObjectDetectionDescription[]
        ).map((objectDescription) => {
            return (
                <Button
                    key={objectDescription.label}
                    color={objectColor(objectDescription)}
                    content={objectDescription.label}
                    style={{ marginBottom: "4px" }}
                    onClick={() =>
                        this.props.createInstance(
                            AnnotationCategories.ObjectDetection,
                            ObjectDetectionAnnotationTypes.Generic,
                            objectDescription
                        )
                    }
                />
            );
        });

        return (
            <>
                <Header as="h3">Add new object:</Header>
                {newInstanceButtonsJsx}
            </>
        );
    }

    generateObjectDetectionNewInstanceJsx() {
        const instance = this.props.focussedInstanceInfo?.instance as ObjectDetectionInstance;

        return (
            <>
                <Header
                    as="h4"
                    content={`Create a new ${instance.label}`}
                    subheader="Left click to add a point. Right click to finish the object. Middle click to add an obscured midhip."
                />
                <Message>
                    <Message.Header>Click the next point</Message.Header>
                    {instance.shape_type === "centeredRectangle" && (
                        <>
                            <Container>
                                The first two points correspond to the bounding box, while the third
                                represents the midhip
                            </Container>

                            <Segment>
                                {`Obscured    `}
                                <Icon name="circle" color="orange" />
                                {"   Visible"}
                                <Icon name="circle" color="green" />
                            </Segment>
                        </>
                    )}
                </Message>
                <Button
                    labelPosition="left"
                    icon="undo"
                    content="Undo"
                    onClick={() => {
                        if (instance.points.length === 0) {
                            this.props.removeInstance(
                                AnnotationCategories.ObjectDetection,
                                ObjectDetectionAnnotationTypes.Generic,
                                instance
                            );
                            this.props.setMode(Mode.Overview);
                            return;
                        }

                        instance.points.pop();
                        this.props.updateInstance({
                            category: AnnotationCategories.ObjectDetection,
                            type: ObjectDetectionAnnotationTypes.Generic,
                            instance: instance,
                        });
                    }}
                />
                <Button
                    labelPosition="left"
                    icon="thumbs up outline"
                    content="Done"
                    color="blue"
                    onClick={() => this.props.setMode(Mode.Overview)}
                />
            </>
        );
    }

    generateKeypointNewInstanceJsx() {
        if (!this.props.focussedInstanceInfo) return;
        if (!this.store) return <Loader active />;

        const instance = this.props.focussedInstanceInfo.instance as KeypointInstance;
        const annotationType = this.store.findAnnotationType(
            this.props.focussedInstanceInfo.category,
            this.props.focussedInstanceInfo.type
        );

        if (!annotationType) return <Loader active />;

        const currentKeypointDefinitions = annotationType.definition[
            instance.keypoints.length
        ] as KeyPointDescription;

        if (!currentKeypointDefinitions) return <></>;

        return (
            <>
                <Header
                    as="h4"
                    content="Add new keypoints"
                    subheader="Left click to add a point. Middle click to add an occluded point. Right click to skip."
                />
                {this.keypointsLegend()}
                <Message>
                    <Message.Header>Click the {currentKeypointDefinitions.name}</Message.Header>
                    <p>{currentKeypointDefinitions.description}</p>
                </Message>
                <Button
                    labelPosition="left"
                    icon="undo"
                    content="Undo"
                    onClick={() => {
                        instance.keypoints.pop();
                        this.props.updateInstance({
                            category: AnnotationCategories.Keypoints,
                            type: KeypointAnnotationTypes.Humans,
                            instance: instance,
                        });
                    }}
                />
                <Button
                    labelPosition="left"
                    icon="trash"
                    content="Cancel"
                    color="red"
                    onClick={() => {
                        this.props.removeInstance(
                            this.props.focussedInstanceInfo!.category,
                            this.props.focussedInstanceInfo!.type,
                            instance
                        );
                        this.props.setMode(Mode.Overview);
                    }}
                />
            </>
        );
    }

    generateNewInstanceControls() {
        if (!this.props.focussedInstanceInfo) return;
        const instanceInfo = this.props.focussedInstanceInfo;

        if (instanceInfo.category === AnnotationCategories.ObjectDetection) {
            if (instanceInfo.type === ObjectDetectionAnnotationTypes.Generic) {
                return this.generateObjectDetectionNewInstanceJsx();
            }
        } else if (instanceInfo.category === AnnotationCategories.Keypoints) {
            if (instanceInfo.type === KeypointAnnotationTypes.Humans) {
                return this.generateKeypointNewInstanceJsx();
            }
        }

        return <>{"Invalid instance creation requested."}</>;
    }

    private generateEditInstanceControls() {
        const category = this.props.focussedInstanceInfo?.category;
        // note(Alex.Shaw): Add spaces before uppercase characters
        const name = category ? category.replace(/([A-Z])/g, " $1") : "";

        return (
            <>
                <Header
                    as="h4"
                    content={`Editing ${name} Instance`}
                    subheader="Right click near a point to delete it, then left click to re-add it."
                />
                {category === AnnotationCategories.Keypoints && this.keypointsLegend()}
                <Button
                    labelPosition="left"
                    icon="thumbs up outline"
                    content="Done"
                    color="blue"
                    onClick={() => this.props.setMode(Mode.Overview)}
                />
            </>
        );
    }

    private keypointsLegend() {
        return (
            <SegmentGroup>
                <Segment textAlign="center">
                    {"Obscured    "}
                    <Icon name="circle" color="blue" />
                    {"   Visible   "}
                    <Icon name="circle" color="yellow" />
                    {"/ "}
                    <Icon name="circle" color="pink" />
                </Segment>
                <Segment textAlign="center">
                    {"Left    "}
                    <Icon name="minus" style={{ color: "rgb(0, 0, 255)" }} />
                    {"   Right   "}
                    <Icon name="minus" style={{ color: "rgb(255, 0, 0)" }} />
                    {"   Central"}
                    <Icon name="minus" style={{ color: "rgb(0, 255, 0)" }} />
                </Segment>
            </SegmentGroup>
        );
    }

    render() {
        if (this.props.mode === Mode.Overview) {
            return this.generateObjectDetectionCreateInstanceButtonsJsx();
        }

        if (this.props.mode === Mode.NewInstance && this.props.focussedInstanceInfo) {
            return this.generateNewInstanceControls();
        }

        if (this.props.mode === Mode.EditInstance && this.props.focussedInstanceInfo) {
            return this.generateEditInstanceControls();
        }
    }
}
