import { inject } from "mobx-react";
import React from "react";
import { Definition, SkeletonAnnotationAnswer } from "../../models/model_types/keypoints";
import { Image as SVGImage, InstanceGroupCrossValidation } from "../annotation/KeyPoint/Image";
import { ProvidedAppStore } from "../../store/AppStore";
import { Image } from "../../models/Image";
import {
    Button,
    Divider,
    Grid,
    Icon,
    Input,
    Label,
    Popup,
    Segment,
    SemanticCOLORS,
} from "semantic-ui-react";

interface KeyPointExplorerProps {
    image: Image;
}

interface GroupName {
    name: string;
    color: string;
}

interface State {
    visibleGroups: InstanceGroupCrossValidation[];
    threshold: number;
    differentAnnotationUsers: string[];
}

const colors = ["orange", "olive", "purple", "pink", "blue", "yellow"];
const initialTheshold = 30;

type Props = KeyPointExplorerProps & ProvidedAppStore;
@inject("store")
export class KeyPointCrossValExplorer extends React.Component<Props, State> {
    private groupNames: GroupName[] = [];
    private noAnnotations: string[] = [];
    private discarded: string[] = [];

    constructor(props: Props) {
        super(props);
        this.state = {
            visibleGroups: [],
            threshold: initialTheshold,
            differentAnnotationUsers: [],
        };
    }

    componentDidMount(): void {
        window.scrollTo(0, 0);
        this.loadAnnotations();
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>): void {
        if (this.props.image !== prevProps.image) {
            this.loadAnnotations();
        }

        if (this.state.threshold != prevState.threshold) {
            this.checkIfDifferentAnnotation(this.state.visibleGroups, this.state.threshold);
        }
    }

    loadAnnotations = () => {
        const { annotations } = this.props.image;
        const initialGroups: InstanceGroupCrossValidation[] = [];
        const labelOpacities: { [key: string]: number } = {};
        this.groupNames = [];
        this.noAnnotations = [];
        this.discarded = [];

        for (let idx = 0; idx < annotations.length; ++idx) {
            const annotation = annotations[idx];
            const color = colors[idx % colors.length];
            const answer = annotation.answerData as SkeletonAnnotationAnswer;
            if (answer.instances.length > 0) {
                this.groupNames.push({ color: color, name: annotation.user.name });
                initialGroups.push({ color: color, instances: answer.instances, opacity: 1 });
                labelOpacities[annotation.user.name] = 1;
            } else if (annotation.discarded) {
                this.discarded.push(annotation.user.name);
            } else {
                this.noAnnotations.push(annotation.user.name);
            }
        }

        this.setState({ visibleGroups: initialGroups });
        this.checkIfDifferentAnnotation(initialGroups, this.state.threshold);
    };

    // This function will check if the cross validation annotations belong to the same object.
    // For that it will estimate a pairwise Euclidean Distance.
    // If all the joints are above a defined threshod, then they will be considered as different objects.
    checkIfDifferentAnnotation = (
        initialGroups: InstanceGroupCrossValidation[],
        threshold: number
    ) => {
        let usersWithDifferentAnnotations = [];
        for (let i = 0; i < initialGroups.length; i++) {
            let isDifferent = 0;
            for (let j = 0; j < initialGroups.length; j++) {
                if (i !== j) {
                    const keypointsA = initialGroups[i].instances[0].keypoints;
                    const keypointsB = initialGroups[j].instances[0].keypoints;

                    const keypointsEuclideanDistance = keypointsA.map((pointA, index) => {
                        // If there is a keypoint annotation missing in one of the annotation, we consider them as different annotations
                        if (keypointsB.length < index + 1) {
                            return threshold + 1;
                            // If both points are visible we estimate the Euclidean Distance
                        } else if (pointA.visible && keypointsB[index].visible) {
                            return Math.sqrt(
                                Math.pow(Math.abs(pointA.pixelX - keypointsB[index].pixelX), 2) +
                                    Math.pow(Math.abs(pointA.pixelY - keypointsB[index].pixelY), 2)
                            );
                            // If one of them is visible and the other is not, then we return then we should consider them as different annotations
                        } else if (pointA.visible || keypointsB[index].visible) {
                            return threshold + 1;
                            // If both of them are not visiible, then is the same annotations.
                        } else {
                            return 0;
                        }
                    });
                    if (
                        keypointsEuclideanDistance.every(
                            (keypointsEuclideanDistance) => keypointsEuclideanDistance >= threshold
                        )
                    )
                        isDifferent += 1;
                }
            }

            if (isDifferent >= initialGroups.length - isDifferent) {
                const matchingAnnotation = this.props.image.annotations.find((annotation) =>
                    annotation.answerData.instances.some(
                        (instance: { keypoints: any }) =>
                            JSON.stringify(instance.keypoints) ===
                            JSON.stringify(initialGroups[i].instances[0].keypoints)
                    )
                );
                if (matchingAnnotation) {
                    usersWithDifferentAnnotations.push(matchingAnnotation.user.name);
                }
            }
        }
        this.setState({ differentAnnotationUsers: usersWithDifferentAnnotations });
    };

    toggleGroupVisibility = (groupName: string, color: string, opacity: number) => {
        this.setState(
            (prevState) => {
                const { visibleGroups } = prevState;
                const isVisible = visibleGroups.some(
                    (group) =>
                        group.instances ===
                        this.props.image.annotations.find(
                            (annotation) => annotation.user.name === groupName
                        )?.answerData.instances
                );

                if (isVisible) {
                    return {
                        visibleGroups: visibleGroups.filter(
                            (group) =>
                                group.instances !==
                                this.props.image.annotations.find(
                                    (annotation) => annotation.user.name === groupName
                                )?.answerData.instances
                        ),
                    };
                } else {
                    // Find the group to add
                    const groupToAdd = this.props.image.annotations.find(
                        (annotation) => annotation.user.name === groupName
                    );
                    return {
                        visibleGroups: groupToAdd
                            ? [
                                  ...visibleGroups,
                                  {
                                      color: color,
                                      instances: groupToAdd.answerData.instances,
                                      opacity: opacity,
                                  },
                              ]
                            : visibleGroups,
                    };
                }
            },
            () => {
                this.checkIfDifferentAnnotation(this.state.visibleGroups, this.state.threshold);
            }
        );
    };

    handleOpacities = (userName: string, opacity: number) => {
        this.setState((prevState) => {
            const { visibleGroups } = prevState;

            const updateGroup = visibleGroups.map((group) => {
                if (
                    group.instances ===
                    this.props.image.annotations.find(
                        (annotation) => annotation.user.name === userName
                    )?.answerData.instances
                ) {
                    return { ...group, opacity };
                }
                return group;
            });

            return { visibleGroups: updateGroup };
        });
    };

    render() {
        const { modelType, bucketPath, annotations } = this.props.image;
        const definition = modelType.definition as Definition;
        const { differentAnnotationUsers, threshold, visibleGroups } = this.state;
        let tempTheshold = threshold;

        const empty = (
            <div style={{ marginLeft: "85px" }}>
                <Icon name="ban" />
            </div>
        );
        const withAnnotationsJSX =
            this.groupNames.length > 0
                ? this.groupNames.map((group: GroupName, idx: number) => {
                      const opacity =
                          visibleGroups.find(
                              (g) =>
                                  g.instances ===
                                  annotations.find(
                                      (annotation) => annotation.user.name === group.name
                                  )?.answerData.instances
                          )?.opacity || 1;

                      const disable =
                          visibleGroups.find((g) => g.color === group.color) === undefined;
                      return (
                          <div>
                              <div style={{ display: "flex", alignItems: "center" }}>
                                  <Label
                                      color={group.color as SemanticCOLORS}
                                      key={idx}
                                      onClick={() =>
                                          this.toggleGroupVisibility(
                                              group.name,
                                              group.color,
                                              opacity
                                          )
                                      }
                                      style={{
                                          cursor: "pointer",
                                          textDecoration: disable ? "line-through black" : "none",
                                          whiteSpace: "nowrap",
                                      }}
                                  >
                                      {group.name}
                                  </Label>
                                  {differentAnnotationUsers.includes(group.name) &&
                                      differentAnnotationUsers.length + 2 <=
                                          visibleGroups.length && (
                                          <Popup
                                              content="Looks like a different object has been annotated."
                                              trigger={
                                                  <Icon
                                                      name="warning sign"
                                                      color="red"
                                                      style={{ animation: "flash 2s infinite" }}
                                                  />
                                              }
                                          />
                                      )}
                              </div>

                              <Input
                                  type="range"
                                  min="0.1"
                                  max="1.0"
                                  step="0.01"
                                  value={opacity}
                                  disabled={disable}
                                  onChange={(e) =>
                                      this.handleOpacities(group.name, parseFloat(e.target.value))
                                  }
                              ></Input>
                          </div>
                      );
                  })
                : empty;

        const noAnnotationJSX =
            this.noAnnotations.length > 0
                ? this.noAnnotations.map((user: string) => {
                      return (
                          <div style={{ marginTop: "10px", whiteSpace: "nowrap" }}>
                              <Label color={"grey" as SemanticCOLORS}>{user}</Label>
                          </div>
                      );
                  })
                : empty;

        const discardedJSX =
            this.discarded.length > 0
                ? this.discarded.map((user: string) => {
                      return (
                          <div style={{ marginTop: "10px", whiteSpace: "nowrap" }}>
                              <Label color={"grey" as SemanticCOLORS}>{user}</Label>
                          </div>
                      );
                  })
                : empty;

        return (
            <>
                <Grid>
                    <Grid.Row style={{ marginTop: "40px" }}>
                        <Grid.Column width={10} textAlign="left">
                            <SVGImage
                                groups={visibleGroups}
                                url={this.props.store!.hwkflowClient.getImageUrl(bucketPath)}
                                definition={definition.keypoints}
                            />
                        </Grid.Column>
                        <Grid.Column widht={8} textAlign="left">
                            <h2
                                style={{
                                    display: "flex",
                                    flexDirection: "column-reverse",
                                    marginTop: "20px",
                                    paddingLeft: "50px",
                                }}
                            >
                                Annotators
                            </h2>
                            <Segment
                                style={{
                                    border: "1px solid #dee2e6",
                                    padding: "15px",
                                    paddingRight: "220px",
                                }}
                            >
                                <h4 style={{ whiteSpace: "nowrap" }}>
                                    <Icon name="bookmark" />
                                    {"With annotation "}
                                    {differentAnnotationUsers.length === visibleGroups.length && (
                                        <Popup
                                            content="Looks like different objects have been annotated"
                                            trigger={
                                                <Icon
                                                    name="warning sign"
                                                    color="red"
                                                    style={{ animation: "flash 1s infinite" }}
                                                />
                                            }
                                        />
                                    )}
                                </h4>
                                {withAnnotationsJSX}

                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <Input
                                        label="Threshold"
                                        labelPosition="left"
                                        type="number"
                                        placeholder={threshold}
                                        style={{
                                            maxWidth: "80px",
                                        }}
                                        onChange={(e) => (tempTheshold = parseInt(e.target.value))}
                                        action={
                                            <Button
                                                color="blue"
                                                icon="refresh"
                                                onClick={() =>
                                                    this.setState({ threshold: tempTheshold })
                                                }
                                            />
                                        }
                                    ></Input>
                                </div>

                                <h4 style={{ whiteSpace: "nowrap" }}>
                                    <Icon name="bookmark outline" /> Not annotated
                                </h4>
                                {noAnnotationJSX}

                                <h4 style={{ whiteSpace: "nowrap" }}>
                                    <Icon name="trash" />
                                    Discarded
                                </h4>
                                {discardedJSX}
                            </Segment>
                        </Grid.Column>
                    </Grid.Row>
                    <Divider hidden={true} />
                </Grid>
            </>
        );
    }
}
