import { action, makeAutoObservable, observable } from "mobx";
import { HwkFlowApiClient } from "./Client";
import { CameraType, DatasetType, ImageScanType, ImageState } from "../models/Image";
import { KeyPointDescription } from "../models/model_types/keypoints";
import { ObjectDetectionDescription } from "../models/model_types/object_detection";
import { AnyInstance } from "../components/media/MediaAnnotation";
import { User } from "../models/User";
import { TaskPriority, TaskState } from "../models/Task";

export enum AnnotationState {
    NeedsVerification = "NeedsVerification",
    Declined = "Declined",
    Verified = "Verified",
    Discarded = "Discarded",
}

export interface FilterState {
    mediaStates: string[];
    addSports: string[];
    addStadiums: string[];
    mediaFormats: string[];
    imageScanTypes: string[];
    cameraTypes: string[];
    applications: string[];
    tags: string[];
    startDate: Date;
    endDate: Date;
    intersectTags: boolean;
}

interface FilterOptions {
    availableSports: string[];
    availableStadiums: string[];
    availableApplications: string[];
    availableTags: string[];
}

export interface MediaAnnotationSubmissionRequest {
    mediaId: number;
    answerData: any;
    annotationCategory: AnnotationCategories;
    annotationType: AnnotationTypes;
    timestep: number;
    isPartial: boolean;
    completionTime: number;
    comment: string;
    user?: User;
}

export interface MediaAnnotation {
    id: number;
    answerData: { instances: AnyInstance[] };
    mediaId: number;
    annotationCategory: AnnotationCategories;
    annotationType: AnnotationTypes;
    timestep: number;
    createdAt: Date;
    isLatest: boolean;
    isPartial: boolean;
    completionTime: number;
    verified: boolean;
    state: AnnotationState;
    comment: string;
    user: User;
    verifier: User;
    discarded: boolean;
    verifiedAt: Date;
    verificationTime: number;
}

export interface Media {
    id: number;
    bucketPath: string;
    sport: string;
    stadium: string;
    cameraType: CameraType;
    imageScanType: ImageScanType;
    datasetType: DatasetType;
    inputDownsampleFactor: number;
    application: string;
    uploadedAt: Date;
    recordedAt: Date;
    state: ImageState;
    movedToCurrentState: Date;
    tags: string[];
    annotations: MediaAnnotation[];
    fps?: number;
}

export interface MediaUpdateRequest {
    id: number;
    sport?: string;
    stadium?: string;
    cameraType?: CameraType;
    imageScanType?: ImageScanType;
    datasetType?: DatasetType;
    inputDownsampleFactor?: number;
    application?: string;
    recordedAt?: Date;
    state?: ImageState;
    tags?: string[];
    fps?: number;
}

export enum AnnotationCategories {
    ObjectDetection = "ObjectDetection",
    Keypoints = "Keypoints",
}

export enum KeypointAnnotationTypes {
    Humans = "Humans",
}

export enum ObjectDetectionAnnotationTypes {
    Generic = "Generic",
}

export type AnnotationTypes = KeypointAnnotationTypes | ObjectDetectionAnnotationTypes;

export interface AnnotationType {
    id: number;
    category: AnnotationCategories;
    type: AnnotationTypes;
    description: string;
    expectedTime: number;
    definition: KeyPointDescription[] | ObjectDetectionDescription[];
}

export interface AnnotationTypeResponse {
    category: AnnotationCategories;
    type: AnnotationTypes;
}

export interface MediaTaskCreationRequest {
    target: number;
    annotationTypes: AnnotationTypeResponse[];
    deadline: Date;
    priority: TaskPriority;
    name: string;
    instructions: string;
    stadiums: string[];
    applications: string[];
    sports: string[];
    tags: string[];
    earliestUploadDate: Date;
    latestUploadDate: Date;
}

export interface MediaTaskUpdateRequest {
    id: number;
    state?: TaskState;
}

export interface MediaTask {
    id: number;
    target: number;
    annotated: number;
    verified: number;
    discarded: number;
    needsAnnotation: number;
    needsVerification: number;
    annotationTypes: AnnotationTypeResponse[];
    state: TaskState;
    deadline: Date;
    priority: TaskPriority;
    name: string;
    instructions: string;
    stadiums: string[];
    applications: string[];
    sports: string[];
    tags: string[];
    earliestUploadDate: Date;
    latestUploadDate: Date;
    createdAt: Date;
    updatedAt: Date;
}

export const MEDIA_FORMATS = {
    Video: [".mp4"],
    Images: [".jpg", ".jpeg", ".png"],
};

export const DEFAULT_VIDEO_FPS = 60;

export type TopLevelAnnotationCategories = AnnotationCategories.ObjectDetection;

// note(Alex.Shaw): These should only be used when there is no risk of them being
// updated as this may cause side effects with other code using these constants.
const MINIMUM_DATE = new Date(0);
const MAXIMUM_DATE = new Date(8640000000000000);

export class MediaStore {
    client: HwkFlowApiClient;
    constructor(client: HwkFlowApiClient) {
        this.client = client;
        makeAutoObservable(this);
    }

    // Filter

    @observable
    public filterOpen: boolean = false;

    @observable
    public filterState: FilterState = {
        mediaStates: ["NeedsAnnotation"],
        addSports: [],
        addStadiums: [],
        mediaFormats: [],
        imageScanTypes: [],
        cameraTypes: [],
        applications: [],
        tags: [],
        startDate: new Date(0),
        endDate: new Date(),
        intersectTags: false,
    };

    @observable
    public filterOptions: FilterOptions = {
        availableSports: [],
        availableStadiums: [],
        availableApplications: [],
        availableTags: [],
    };

    // Explore

    @observable
    public requestPending: boolean = false;

    @observable
    public filteredMediaCount: number = 0;

    @observable
    public filteredMediaIds: number[] = [];

    @observable
    public currentMedia: Media[] = [];

    @observable
    public currentPage: number = 0;

    @observable
    public pageSize: number = 20;

    @observable
    public totalPages: number = 0;

    @observable
    public videosToPreview: number[] = [];

    // Info Edit
    @observable
    public allSports: string[] = [];

    @observable
    public allTags: string[] = [];

    // Annotation
    @observable
    public annotationTypes: AnnotationType[] = [];

    @action
    public toggleFilterOpen() {
        this.filterOpen = !this.filterOpen;
    }

    @action
    public updateFilterState(newState: FilterState) {
        this.filterState = newState;
    }

    @action
    public async fetchFilterOptions() {
        let filterResponse = await this.client.fetchMediaFilterOptions();
        let tagResponse = await this.client.fetchMediaFilterTags(this.filterState.addSports);
        this.filterOptions = {
            ...filterResponse.data,
            availableTags: tagResponse.data,
        } as FilterOptions;
    }

    @action
    public async fetchFilteredMediaIdsAndCount() {
        this.requestPending = true;
        let response = await this.client.fetchFilteredMediaIdsAndCount(this.filterState);
        this.filteredMediaCount = response.data.count;
        this.filteredMediaIds = response.data.mediaIds;

        this.calculateTotalPages();
        this.setCurrentPage(0);
        this.fetchMediaEntitiesForCurrentExplorePage();
        this.requestPending = false;
    }

    @action
    public async fetchMediaEntitiesForCurrentExplorePage() {
        this.requestPending = true;
        this.clearVideosToPreview();
        let pageStartIndex = this.pageSize * this.currentPage;
        let pageEndIndex = pageStartIndex + this.pageSize;

        let idsToFetch = this.filteredMediaIds.slice(pageStartIndex, pageEndIndex);
        let response = await this.client.fetchMediaByIds(idsToFetch, false);
        // note(alex.shaw): Sort upload date to match /media/find endpoint.
        this.currentMedia = (response.data as Media[]).sort((a: Media, b: Media) => {
            return new Date(b.uploadedAt).getTime() - new Date(a.uploadedAt).getTime();
        });
        this.requestPending = false;
    }

    @action
    public setCurrentPage(page: number) {
        this.clearVideosToPreview();
        this.currentPage = page;
    }

    @action
    public setPageSize(pageSize: number) {
        this.pageSize = pageSize;
    }

    @action
    public calculateTotalPages() {
        this.totalPages = Math.ceil(this.filteredMediaCount / this.pageSize);
    }

    @action
    public updateVideosToPreview(mediaId: number) {
        if (this.videosToPreview.includes(mediaId)) return;
        // note(alex.shaw): Only allow 5 videos to be previewed at a time.
        // After this, remove the oldest preview to make room for the next.
        if (this.videosToPreview.length >= 5) this.videosToPreview.shift();
        this.videosToPreview.push(mediaId);
    }

    @action clearVideosToPreview() {
        this.videosToPreview = [];
    }

    @action
    public fetchAnnotationTypes() {
        return this.client.fetchMediaAnnotationTypes().then((response) => {
            this.annotationTypes = response.data as AnnotationType[];
        });
    }

    @action
    public async fetchAllSports() {
        this.client.fetchAllSports().then((response) => {
            this.allSports = (response.data as string[]).sort((a, b) => a.localeCompare(b));
        });
    }

    @action
    public async fetchAllTags() {
        this.client.fetchTags().then((response) => {
            this.allTags = (response.data as string[]).sort((a, b) => a.localeCompare(b));
        });
    }

    @action
    async updateMedia(media: MediaUpdateRequest) {
        return this.client.updateMedia(media);
    }

    @action
    async submitAnnotation(annotationRequest: MediaAnnotationSubmissionRequest) {
        try {
            return (await this.client.submitMediaAnnotation(annotationRequest)).status === 201;
        } catch (e) {
            return false;
        }
    }

    @action
    async submitTask(
        target: number,
        annotationTypes: AnnotationType[],
        deadline: Date,
        priority: TaskPriority,
        name: string,
        instructions: string,
        stadiums: string[],
        applications: string[],
        sports: string[],
        tags: string[],
        earliestUploadDate?: Date,
        latestUploadDate?: Date
    ) {
        let request = {
            target: target,
            annotationTypes: annotationTypes.map((annotationType) => {
                return { category: annotationType.category, type: annotationType.type };
            }),
            deadline: deadline,
            priority: priority,
            name: name,
            instructions: instructions,
            stadiums: stadiums,
            applications: applications,
            sports: sports,
            tags: tags,
            earliestUploadDate: earliestUploadDate ? earliestUploadDate : MINIMUM_DATE,
            latestUploadDate: latestUploadDate ? latestUploadDate : MAXIMUM_DATE,
        } as MediaTaskCreationRequest;

        try {
            return (await this.client.submitMediaTask(request)).status == 200;
        } catch (e) {
            return false;
        }
    }

    @action
    public async fetchTasks(): Promise<MediaTask[]> {
        return (await this.client.fetchMediaTasks()).data;
    }

    @action
    public async updateTask(id: number, state?: TaskState) {
        return await this.client.updateMediaTask({ id: id, state: state });
    }

    @action async topUpTask(id: number, sourceState: ImageState) {
        return await this.client.topUpMediaTask(id, sourceState);
    }

    public getMediaUrl(bucketPath: string) {
        return this.client.getImageUrl(bucketPath);
    }

    public async getMedia(id: number, includePartialAnnotations?: boolean) {
        return this.client
            .fetchMediaByIds([id], true, includePartialAnnotations)
            .then((response) => {
                let media = response.data as Media[];
                return media.map((m) => {
                    if (!m.fps && !mediaIsImage(m)) m.fps = DEFAULT_VIDEO_FPS;
                    return m;
                })[0];
            });
    }

    public importAnnotationTypes() {
        return this.client.importMediaAnnotationTypeFiles();
    }

    public findAnnotationType(category: AnnotationCategories, type: AnnotationTypes) {
        if (this.annotationTypes.length == 0) return;

        return this.annotationTypes.filter(
            (annotationType) => annotationType.category === category && annotationType.type === type
        )[0];
    }
}

export function mediaIsImage(media: Media): boolean {
    const format = media.bucketPath.split(".")[1];
    return MEDIA_FORMATS.Images.includes(`.${format}`);
}
