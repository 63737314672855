import React from "react";
import { ProvidedAppStore } from "../../../store/AppStore";
import {
    AnnotationCategories,
    AnnotationTypes,
    KeypointAnnotationTypes,
    MediaStore,
    ObjectDetectionAnnotationTypes,
} from "../../../store/MediaStore";
import { inject, observer } from "mobx-react";
import {
    Accordion,
    Button,
    Container,
    Header,
    Icon,
    Loader,
    Modal,
    ModalActions,
    ModalContent,
    ModalHeader,
    Popup,
} from "semantic-ui-react";

import { Instance as KeypointInstance } from "../../../models/model_types/keypoints";
import { Instance as ObjectDetectionInstance } from "../../../models/model_types/object_detection";
import { AnyInstance, InstanceInfo, Mode } from "../MediaAnnotation";

const NON_BREAKING_SPACE = "\u00A0";

interface HierarchyProps {
    objectDetectionInstances: Record<ObjectDetectionAnnotationTypes, ObjectDetectionInstance[]>;
    keypointInstances: Record<KeypointAnnotationTypes, KeypointInstance[]>;
    mode: Mode;
    createInstance: (category: AnnotationCategories, type: AnnotationTypes, metadata: any) => void;
    removeInstance: (
        category: AnnotationCategories,
        type: AnnotationTypes,
        instance: AnyInstance
    ) => void;
    updateInstance: (instanceInfo: InstanceInfo) => void;
    setMode: (mode: Mode) => void;
    setUnsavedChanges: (unsavedChanges: boolean) => void;
    focussedInstanceInfo?: InstanceInfo;
}

interface MediaAnnotationHierarchyState {
    objectDetectionGenericInstances: ObjectDetectionInstance[];
    keypointHumansInstances: KeypointInstance[];
    deleteConfirmationModalActive: boolean;
    deleteConfirmationModalCallback: CallableFunction;
}

type Props = ProvidedAppStore & HierarchyProps;

@inject("store")
@observer
export class Hierarchy extends React.Component<Props, MediaAnnotationHierarchyState> {
    store: MediaStore | undefined;

    constructor(props: Props) {
        super(props);
        this.store = props.store?.mediaStore;
        this.state = {
            objectDetectionGenericInstances: this.props.objectDetectionInstances.Generic,
            keypointHumansInstances: this.props.keypointInstances.Humans,
            deleteConfirmationModalActive: false,
            deleteConfirmationModalCallback: () => {
                return;
            },
        };
    }

    async componentDidUpdate(prevProps: Readonly<Props>) {
        if (
            JSON.stringify(this.props.objectDetectionInstances) !==
            JSON.stringify(prevProps.objectDetectionInstances)
        ) {
            this.setState({
                objectDetectionGenericInstances: this.props.objectDetectionInstances.Generic,
            });
        }

        if (
            JSON.stringify(this.props.keypointInstances) !==
            JSON.stringify(prevProps.keypointInstances)
        ) {
            this.setState({ keypointHumansInstances: this.props.keypointInstances.Humans });
        }
    }

    prepareDeleteModalState(instanceInfo: InstanceInfo) {
        let instancesToDelete = [instanceInfo];

        if (
            instanceInfo.category === AnnotationCategories.ObjectDetection &&
            instanceInfo.type === ObjectDetectionAnnotationTypes.Generic
        ) {
            const instance = instanceInfo.instance as ObjectDetectionInstance;
            const children = this.state.keypointHumansInstances
                .filter(
                    (keypointInstance) => keypointInstance.parentInstanceId === instance.instanceId
                )
                .map((childInstance) => {
                    return {
                        category: AnnotationCategories.Keypoints,
                        type: KeypointAnnotationTypes.Humans,
                        instance: childInstance,
                    };
                });
            instancesToDelete.push(...children);
        }

        this.setState({
            ...this.state,
            deleteConfirmationModalActive: true,
            deleteConfirmationModalCallback: () => {
                instancesToDelete.forEach((toDelete) => {
                    this.props.removeInstance(toDelete.category, toDelete.type, toDelete.instance);
                });
            },
        });
    }

    editInstance(instanceInfo: InstanceInfo) {
        this.props.updateInstance(instanceInfo);
        this.props.setMode(Mode.EditInstance);
        this.props.setUnsavedChanges(true);
    }

    isInstanceFocussed(instanceInfo: InstanceInfo) {
        return JSON.stringify(this.props.focussedInstanceInfo) === JSON.stringify(instanceInfo);
    }

    shouldObjectDetectionAccordionBeActive(instanceInfo: InstanceInfo) {
        if (!this.props.focussedInstanceInfo) return false;
        if (this.isInstanceFocussed(instanceInfo)) return true;

        // Child instance focussed?
        const objectInstance = instanceInfo.instance as ObjectDetectionInstance;
        const childInstance = this.props.focussedInstanceInfo.instance as KeypointInstance;
        return childInstance.parentInstanceId === objectInstance.instanceId;
    }

    handleInstanceCardClick(instanceInfo: InstanceInfo) {
        if (this.props.mode !== Mode.Overview) return;

        const deselect = this.isInstanceFocussed(instanceInfo);
        if (deselect) {
            // note(Alex.Shaw): We are already in Overview Mode, but this also clears the focussed instance.
            this.props.setMode(Mode.Overview);
        } else {
            this.props.updateInstance(instanceInfo);
        }
    }

    isInstanceAllowedChildren(instanceInfo: InstanceInfo) {
        if (
            instanceInfo.category === AnnotationCategories.ObjectDetection &&
            instanceInfo.type === ObjectDetectionAnnotationTypes.Generic
        ) {
            const instance = instanceInfo.instance as ObjectDetectionInstance;
            if (instance.label !== "person") return false;
            const children = this.state.keypointHumansInstances.filter(
                (keypointInstance) => keypointInstance.parentInstanceId === instance.instanceId
            );
            if (children.length === 0) return true;
        }
        return false;
    }

    generateInstanceCard(
        instanceInfo: InstanceInfo,
        key: React.Key,
        label: string,
        highlight: boolean,
        childCategory?: AnnotationCategories,
        createChild?: CallableFunction
    ) {
        let buttonsDisabled = this.props.mode !== Mode.Overview;

        //note(Alex.Shaw): The style object doesnt have a type - set a green border to highlight
        let cardStyle: any = { display: "flex", alignItems: "center", gap: "4px" };
        if (highlight) cardStyle = { ...cardStyle, border: "2px solid #02c012" };

        return (
            <Container
                key={key}
                style={cardStyle}
                className="media-annotation-hierarchy-instance"
                onClick={() => this.handleInstanceCardClick(instanceInfo)}
            >
                {label}
                {childCategory && <Icon name="dropdown" />}
                <Container style={{ display: "flex", justifyContent: "flex-end" }}>
                    {childCategory && (
                        <Popup
                            trigger={
                                <Button
                                    positive
                                    icon="add"
                                    disabled={
                                        buttonsDisabled ||
                                        !this.isInstanceAllowedChildren(instanceInfo)
                                    }
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        if (createChild) createChild();
                                    }}
                                />
                            }
                            on="hover"
                            content={`Add ${childCategory}`}
                        />
                    )}
                    <Button
                        negative
                        disabled={buttonsDisabled}
                        icon="delete"
                        onClick={(event) => {
                            event.stopPropagation();
                            this.prepareDeleteModalState(instanceInfo);
                        }}
                    />
                    <Button
                        disabled={buttonsDisabled}
                        icon="pencil"
                        onClick={(event) => {
                            event.stopPropagation();
                            this.editInstance(instanceInfo);
                        }}
                    />
                </Container>
            </Container>
        );
    }

    generateKeypointHumansInstancesCards(instances: KeypointInstance[], parentIndex: number) {
        return instances.map((keypointInstance, kpIndex) => {
            const keypointInstanceInfo: InstanceInfo = {
                category: AnnotationCategories.Keypoints,
                type: KeypointAnnotationTypes.Humans,
                instance: keypointInstance,
            };
            const highlightInstance =
                this.props.focussedInstanceInfo?.instance === keypointInstance;

            return this.generateInstanceCard(
                keypointInstanceInfo,
                `${parentIndex}_${kpIndex}`,
                "Skeleton",
                highlightInstance
            );
        });
    }

    generateObjectDetectionGenericInstancesAccordion() {
        return this.state.objectDetectionGenericInstances.map((objectInstance, index) => {
            const instanceInfo: InstanceInfo = {
                category: AnnotationCategories.ObjectDetection,
                type: ObjectDetectionAnnotationTypes.Generic,
                instance: objectInstance,
            };
            const highlightInstance = this.props.focussedInstanceInfo?.instance === objectInstance;

            if (objectInstance.label !== "person")
                return this.generateInstanceCard(
                    instanceInfo,
                    index,
                    `${index + 1}.${NON_BREAKING_SPACE}${objectInstance.label}`,
                    highlightInstance
                );

            // note(Alex.Shaw): Person annotations may have keypoints associated
            // with them, so we return an accordion instead of a single instance here.
            const keypointInstances = this.state.keypointHumansInstances.filter(
                (keypointInstance) =>
                    keypointInstance.parentInstanceId === objectInstance.instanceId
            );

            return (
                <Accordion key={index}>
                    <Accordion.Title
                        style={{ padding: 0 }}
                        active={this.shouldObjectDetectionAccordionBeActive(instanceInfo)}
                    >
                        {this.generateInstanceCard(
                            instanceInfo,
                            index,
                            `${index + 1}.${NON_BREAKING_SPACE}${objectInstance.label}`,
                            highlightInstance,
                            AnnotationCategories.Keypoints,
                            () =>
                                this.props.createInstance(
                                    AnnotationCategories.Keypoints,
                                    KeypointAnnotationTypes.Humans,
                                    {
                                        parentInstanceId: objectInstance.instanceId,
                                    }
                                )
                        )}
                    </Accordion.Title>
                    <Accordion.Content
                        active={this.shouldObjectDetectionAccordionBeActive(instanceInfo)}
                        style={{ marginLeft: "16px" }}
                    >
                        <Header as="h4">Human Keypoints</Header>
                        {this.generateKeypointHumansInstancesCards(keypointInstances, index)}
                    </Accordion.Content>
                </Accordion>
            );
        });
    }

    render() {
        if (!this.store?.annotationTypes || this.store.annotationTypes.length === 0)
            return <Loader active />;

        if (this.state.objectDetectionGenericInstances.length === 0)
            return <Header sub>No Object Annotations</Header>;

        return (
            <>
                <Modal
                    closeIcon
                    dimmer="inverted"
                    size="mini"
                    open={this.state.deleteConfirmationModalActive}
                    onClose={() => this.setState({ deleteConfirmationModalActive: false })}
                >
                    <ModalHeader>Delete instance?</ModalHeader>
                    <ModalContent>This action is permanent.</ModalContent>
                    <ModalActions>
                        <Button
                            floated="left"
                            onClick={() => this.setState({ deleteConfirmationModalActive: false })}
                        >
                            Cancel
                        </Button>
                        <Button
                            negative
                            onClick={() => {
                                this.state.deleteConfirmationModalCallback();
                                this.setState({ deleteConfirmationModalActive: false });
                                this.props.setUnsavedChanges(true);
                            }}
                        >
                            Delete
                        </Button>
                    </ModalActions>
                </Modal>
                <Header as="h4">Object Detection:</Header>
                {this.generateObjectDetectionGenericInstancesAccordion()}
            </>
        );
    }
}
