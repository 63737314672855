import React from "react";
import {
    KeyPointDescription,
    Instance as KeypointInstance,
} from "../../../models/model_types/keypoints";
import { Instance as ObjectDetectionInstance } from "../../../models/model_types/object_detection";
import {
    AnnotationCategories,
    AnnotationType,
    KeypointAnnotationTypes,
    ObjectDetectionAnnotationTypes,
} from "../../../store/MediaStore";
import {
    InstanceGroup as ObjectDetectionInstanceGroup,
    svg,
} from "../../annotation/ObjectDetection/Image";
import { InstanceGroup as KeypointInstanceGroup } from "../../annotation/KeyPoint/Image";
import { TrackedCTX } from "../../helpers/TrackedSVG";
import { produce } from "immer";
import {
    createObjectDetectionInstanceDrawingGroup,
    drawObjectDetectionInstance,
} from "./ObjectDetection";
import { InstanceInfo } from "../MediaAnnotation";
import { MouseButtons } from "../../helpers/Mouse";
import { createKeypointInstanceDrawingGroup, drawKeypointInstance } from "./Keypoints";

export type GenericPoint = { x: number; y: number };

interface AnnotationCanvasProps {
    image: ImageBitmap;
    objectDetectionInstances: Record<ObjectDetectionAnnotationTypes, ObjectDetectionInstance[]>;
    keypointInstances: Record<KeypointAnnotationTypes, KeypointInstance[]>;
    annotationTypes: AnnotationType[];
    focussedInstance?: InstanceInfo;

    onClick?: (button: number, x: number, y: number) => void;
    onMouseDown?: (button: number, x: number, y: number) => void;
    onMove?: (x: number, y: number) => void;
}

interface AnnotationCanvasState {
    objectInstanceGroups: ObjectDetectionInstanceGroup[];
    keypointInstanceGroups: KeypointInstanceGroup[];

    imageScale: number;
    beingDragged: boolean;
    dragStarted: boolean;
    imageWidth: number;
    imageHeight: number;

    translation: GenericPoint;
    delta: GenericPoint;
}

export class AnnotationCanvas extends React.Component<
    AnnotationCanvasProps,
    AnnotationCanvasState
> {
    private canvasElement?: HTMLCanvasElement;
    private zoomCanvasElement?: HTMLCanvasElement;
    private divElement?: HTMLDivElement;

    // note(alex.shaw): These objects dont work well in state
    private trackedCTX?: TrackedCTX;
    private lastClick: SVGPoint;
    private lastPosition: SVGPoint;

    constructor(props: AnnotationCanvasProps) {
        super(props);
        this.state = {
            objectInstanceGroups: [],
            keypointInstanceGroups: [],
            imageScale: 0,
            beingDragged: false,
            dragStarted: false,
            imageWidth: props.image.width,
            imageHeight: props.image.height,
            translation: { x: 0, y: 0 },
            delta: { x: 0, y: 0 },
        };

        this.lastClick = svg.createSVGPoint();
        this.lastPosition = svg.createSVGPoint();

        this.onMouseDown = this.onMouseDown.bind(this);
        this.onMouseUp = this.onMouseUp.bind(this);
        this.onMouseMove = this.onMouseMove.bind(this);
        this.onMouseWheel = this.onMouseWheel.bind(this);
        this.onResize = this.onResize.bind(this);
    }

    componentDidMount(): void {
        if (!this.canvasElement || !this.divElement) return;

        this.canvasElement.onmousedown = this.onMouseDown;
        this.canvasElement.onmousemove = this.onMouseMove;
        this.canvasElement.onmouseup = this.onMouseUp;
        this.canvasElement.onwheel = this.onMouseWheel;
        this.canvasElement.addEventListener("wheel", this.onMouseWheel, false);
        window.addEventListener("resize", this.onResize, true);

        this.canvasElement.oncontextmenu = function (event: MouseEvent) {
            event.preventDefault();
        };

        this.setCanvasSize(this.divElement.clientWidth, this.canvasElement);

        this.lastPosition.x = 0;
        this.lastPosition.y = 0;
        this.lastClick = this.lastPosition;

        const ctx = this.canvasElement.getContext("2d");
        if (ctx) {
            this.trackedCTX = new TrackedCTX(ctx);
            this.drawImageAndAnnotations();
        }
    }

    componentDidUpdate(
        prevProps: Readonly<AnnotationCanvasProps>,
        prevState: Readonly<AnnotationCanvasState>
    ) {
        const stateChanged = JSON.stringify(prevState) !== JSON.stringify(this.state);
        const objectsChanged =
            JSON.stringify(prevProps.objectDetectionInstances) !==
            JSON.stringify(this.props.objectDetectionInstances);
        const keypointsChanged =
            JSON.stringify(prevProps.keypointInstances) !==
            JSON.stringify(this.props.keypointInstances);

        if (
            prevProps.image !== this.props.image ||
            prevProps.focussedInstance !== this.props.focussedInstance ||
            stateChanged ||
            objectsChanged ||
            keypointsChanged
        ) {
            this.drawImageAndAnnotations();
        }
    }

    private onMouseDown(event: MouseEvent) {
        if (!this.trackedCTX) return;

        if (event.button === MouseButtons.LEFT_CLICK) {
            this.setState({ ...this.state, beingDragged: false, dragStarted: true });
            this.lastClick = this.trackedCTX.transformedPoint(event.offsetX, event.offsetY);
        }
    }

    private onMouseMove(event: MouseEvent) {
        if (!this.trackedCTX) return;

        // Image dragging
        this.lastPosition = this.trackedCTX.transformedPoint(event.offsetX, event.offsetY);

        if (this.state.dragStarted) {
            this.setState({ beingDragged: true });

            const deltaX = this.lastPosition.x - this.lastClick.x;
            const deltaY = this.lastPosition.y - this.lastClick.y;
            this.setState({
                delta: produce(this.state.delta, (draft) => {
                    draft.x += deltaX;
                    draft.y += deltaY;
                }),
            });

            this.trackedCTX.translate(deltaX, deltaY);
        }

        this.drawImageAndAnnotations();

        // onMove callback
        if (!this.props.onMove) return;

        const imageX = this.lastPosition.x / this.state.imageScale;
        const imageY = this.lastPosition.y / this.state.imageScale;
        if (
            imageX < 0 ||
            imageX >= this.state.imageWidth ||
            imageY < 0 ||
            imageY >= this.state.imageHeight
        ) {
            return;
        }

        this.props.onMove(imageX, imageY);
    }

    private onMouseWheel(event: WheelEvent) {
        if (!this.trackedCTX) return;
        event.preventDefault();

        const wheelDelta = event.deltaY ? -event.deltaY / 130 : -event.detail ? event.detail : 0;
        const scaleFactor = 1.05;
        const zoomFactor = Math.pow(scaleFactor, wheelDelta);

        // note(Alex.Shaw): We expand the translation here to pass it in as a new object.
        // If we didnt do this, the scale() method would receive this by reference and
        // therefore would update the state improperly as it assigns this variable in its body.
        let newTranslation: GenericPoint = this.trackedCTX.scale(zoomFactor, this.lastPosition, {
            ...this.state.translation,
        });

        this.setState({
            translation: newTranslation,
        });

        // In some edge cases the newTranslation can match the translation already in state,
        // so we call the draw function here to ensure redraws happen when this edge case is met.
        this.drawImageAndAnnotations();
    }

    private onMouseUp(event: MouseEvent) {
        const wasBeingDragged = this.state.beingDragged && this.state.dragStarted;
        this.setState({ ...this.state, beingDragged: false, dragStarted: false });

        if (wasBeingDragged || !this.props.onClick || !this.trackedCTX) return;

        const canvasPoint = this.trackedCTX.transformedPoint(event.offsetX, event.offsetY);
        const imageX = canvasPoint.x / this.state.imageScale;
        const imageY = canvasPoint.y / this.state.imageScale;
        if (
            imageX < 0 ||
            imageX >= this.state.imageWidth ||
            imageY < 0 ||
            imageY >= this.state.imageHeight
        ) {
            return;
        }
        // note(Alex.Shaw): This function updates focussedInstance, which in turn
        // triggers a re-draw of the annotation with the new point.
        this.props.onClick(event.button, imageX, imageY);
    }

    private onResize() {
        if (this.canvasElement == null || this.divElement == null) return;
        this.setCanvasSize(this.divElement.clientWidth, this.canvasElement);
        this.drawImageAndAnnotations();
    }

    private setCanvasSize(maxCanvasSize: number, canvas: HTMLCanvasElement) {
        const maxImageSize = Math.max(this.state.imageWidth, this.state.imageHeight);
        canvas.width = this.state.imageWidth / (maxImageSize / maxCanvasSize);
        canvas.height = this.state.imageHeight / (maxImageSize / maxCanvasSize);
    }

    private calculateCanvasToImageScale(canvas: HTMLCanvasElement) {
        const scaleWidth = canvas.width / this.state.imageWidth;
        const scaleHeight = canvas.height / this.state.imageHeight;
        const scale = scaleWidth < scaleHeight ? scaleWidth : scaleHeight;
        return scale;
    }

    private calculateImageDrawSize(scale: number) {
        const canvasImageWidth = this.state.imageWidth * scale;
        const canvasImageHeight = this.state.imageHeight * scale;
        return { width: canvasImageWidth, height: canvasImageHeight };
    }

    private drawImageAndAnnotations() {
        this.drawImage();
        this.drawAnnotations();
        this.drawCrosshair(this.lastPosition);
    }

    private drawCrosshair(mousePosition: SVGPoint) {
        if (this.canvasElement == null || !this.trackedCTX) return;
        const ctx = this.canvasElement.getContext("2d");
        if (!ctx) return;
        ctx.beginPath();
        ctx.lineWidth = 0.5;

        ctx.moveTo(mousePosition.x, 0);
        ctx.lineTo(mousePosition.x, mousePosition.y - 5);
        ctx.moveTo(mousePosition.x, mousePosition.y + 5);
        ctx.lineTo(mousePosition.x, this.canvasElement.clientHeight);

        ctx.moveTo(0, mousePosition.y);
        ctx.lineTo(mousePosition.x - 5, mousePosition.y);
        ctx.moveTo(mousePosition.x + 5, mousePosition.y);
        ctx.lineTo(this.canvasElement.clientWidth, mousePosition.y);

        ctx.strokeStyle = "rgba(0,102,255,0.67)";
        ctx.stroke();
        ctx.closePath();
    }

    private drawAnnotations() {
        if (this.canvasElement == null || !this.trackedCTX) return;

        const ctx = this.canvasElement.getContext("2d");
        if (!ctx) return;

        const activeOpacity = 1;
        const inactiveOpacity = 0.5;

        // Object Detection
        let objectDetectionGroups = createObjectDetectionInstanceDrawingGroup(
            this.props.objectDetectionInstances.Generic,
            this.props.focussedInstance?.instance as ObjectDetectionInstance
        );

        const objectDetectionActive =
            this.props.focussedInstance?.category === AnnotationCategories.ObjectDetection ||
            !this.props.focussedInstance;

        for (const group of objectDetectionGroups) {
            const { color, instances } = group;
            for (const drawingInstance of instances) {
                const instance = drawingInstance.instance;
                drawObjectDetectionInstance(
                    ctx,
                    this.trackedCTX,
                    instance,
                    color,
                    this.state.imageScale,
                    drawingInstance.activeVertex,
                    objectDetectionActive ? activeOpacity : inactiveOpacity
                );
            }
        }

        // Keypoint Humans
        let keypointGroups = createKeypointInstanceDrawingGroup(
            this.props.keypointInstances.Humans,
            this.props.focussedInstance?.instance as KeypointInstance
        );

        const annotationType = this.props.annotationTypes.filter((annotationType) => {
            return (
                annotationType.category === AnnotationCategories.Keypoints &&
                annotationType.type === KeypointAnnotationTypes.Humans
            );
        })[0];
        const keypointHumansDefinitions = annotationType!.definition as KeyPointDescription[];

        const keypointsActive =
            this.props.focussedInstance?.category === AnnotationCategories.Keypoints ||
            !this.props.focussedInstance;

        for (const keypointGroup of keypointGroups) {
            const { color, instances } = keypointGroup;
            for (const instance of instances) {
                drawKeypointInstance(
                    ctx,
                    this.trackedCTX,
                    instance.keypoints,
                    keypointHumansDefinitions,
                    color,
                    this.state.imageScale,
                    false,
                    keypointsActive ? activeOpacity : inactiveOpacity
                );
            }
        }
    }

    private drawImage() {
        if (
            this.canvasElement == null ||
            this.zoomCanvasElement == null ||
            this.divElement == null ||
            !this.trackedCTX
        ) {
            return;
        }

        const ctx = this.canvasElement.getContext("2d");
        const ctxZoom = this.zoomCanvasElement.getContext("2d");
        if (!ctx || !ctxZoom) {
            return;
        }

        ctx.save();
        ctx.setTransform(1, 0, 0, 1, 0, 0);
        ctx.clearRect(0, 0, this.canvasElement.width, this.canvasElement.height);
        ctx.restore();

        const imageScale = this.calculateCanvasToImageScale(this.canvasElement);
        this.setState({ imageScale: imageScale });
        const imageDrawSizes = this.calculateImageDrawSize(imageScale);
        ctx.drawImage(this.props.image, 0, 0, imageDrawSizes.width, imageDrawSizes.height);

        // Draw zoom preview
        this.setCanvasSize(this.divElement?.clientWidth / 5, this.zoomCanvasElement);
        const imageZoomScale = this.calculateCanvasToImageScale(this.zoomCanvasElement);
        const imageDrawSizesZoom = this.calculateImageDrawSize(imageZoomScale);
        ctxZoom.drawImage(
            this.props.image,
            0,
            0,
            imageDrawSizesZoom.width,
            imageDrawSizesZoom.height
        );

        // Add zoom rectagle
        const scale = this.trackedCTX.getScale();
        if (scale === 1) {
            this.setState({ delta: { x: 0, y: 0 } });
        }

        const widthScale = this.zoomCanvasElement.width / this.canvasElement.width;
        const heightScale = this.zoomCanvasElement.height / this.canvasElement.height;
        const rectX =
            ((this.lastPosition.x - this.state.delta.x) * (scale - 1) * widthScale) / scale;
        const rectY =
            ((this.lastPosition.y - this.state.delta.y) * (scale - 1) * heightScale) / scale;
        const rectWidth = this.zoomCanvasElement.width / scale;
        const rectHeight = this.zoomCanvasElement.height / scale;

        ctxZoom.strokeStyle = "red";
        ctxZoom.lineWidth = 2;
        ctxZoom.strokeRect(rectX, rectY, rectWidth, rectHeight);
    }

    render() {
        return (
            <div
                className="MediaAnnotationCanvas"
                ref={(child: HTMLDivElement) => (this.divElement = child)}
            >
                <canvas
                    ref={(child: HTMLCanvasElement) => (this.canvasElement = child)}
                    width={`100%`}
                    height={`100%`}
                    style={{ border: "1px solid gray" }}
                />
                <canvas
                    ref={(child: HTMLCanvasElement) => (this.zoomCanvasElement = child)}
                    width={150}
                    height={150}
                    style={{
                        border: "1px solid gray",
                        position: "absolute",
                        top: "0px",
                        right: "-175px",
                        zIndex: 10,
                    }}
                />
            </div>
        );
    }
}
