export const POINT_DELETION_DISTANCE_THRESHOLD = 15;

export enum MouseButtons {
    LEFT_CLICK,
    MIDDLE_CLICK,
    RIGHT_CLICK,
}

export function findClosestPointIndex<T extends { pixelX: number; pixelY: number }[]>(
    x: number,
    y: number,
    points: T
) {
    let pointDistance = Number.MAX_VALUE;
    let closestPointIdx = -1;

    for (let vertexIdx = 0; vertexIdx < points.length; ++vertexIdx) {
        const point = points[vertexIdx];

        const deltaX = x - point.pixelX;
        const deltaY = y - point.pixelY;
        const distance = Math.sqrt(Math.pow(deltaX, 2) + Math.pow(deltaY, 2));

        if (distance < pointDistance) {
            pointDistance = distance;
            closestPointIdx = vertexIdx;
        }
    }

    return { pointDistance, closestPointIdx };
}
