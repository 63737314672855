import { AssertionError } from "assert";

export interface Point {
    pixelX: number;
    pixelY: number;
    obscured: boolean;
    visible: boolean;
}

// This is different from Keypoint description - each instance has one of these, not all of them!
export interface ObjectDetectionDescription {
    label: string;
    shape_type: string;
    instanceId?: string;
}

export interface Instance extends ObjectDetectionDescription {
    points: Point[];
    max_points: number;

    shape(): Point[];
    area(): number | undefined;
}

export class UndefinedInstance implements Instance {
    label: string = "";
    shape_type: string = "undefined";
    points: Point[] = [];
    max_points: number = -1;
    shape(): Point[] {
        throw new Error("Method not implemented.");
    }
    area(): number | undefined {
        throw new Error("Method not implemented.");
    }
}

export class BoundingBoxInstance implements Instance {
    label: string;
    shape_type: string = "rectangle";
    points: Point[] = [];
    max_points: number = 2;

    constructor(label: string) {
        this.label = label;
    }

    shape(): Point[] {
        if (this.points.length < 2) {
            return this.points;
        }

        const vertex_a = this.points[0];
        const vertex_b = this.points[1];

        const min_x = Math.min(vertex_a.pixelX, vertex_b.pixelX);
        const min_y = Math.min(vertex_a.pixelY, vertex_b.pixelY);
        const max_x = Math.max(vertex_a.pixelX, vertex_b.pixelX);
        const max_y = Math.max(vertex_a.pixelY, vertex_b.pixelY);

        return [
            { pixelX: min_x, pixelY: min_y, obscured: false, visible: true },
            { pixelX: max_x, pixelY: min_y, obscured: false, visible: true },
            { pixelX: max_x, pixelY: max_y, obscured: false, visible: true },
            { pixelX: min_x, pixelY: max_y, obscured: false, visible: true },
        ];
    }

    area(): number | undefined {
        if (this.shape().length < 4) return 0;

        let x = this.shape()[1].pixelX - this.shape()[0].pixelX;
        let y = this.shape()[2].pixelY - this.shape()[1].pixelY;
        return x * y;
    }
}

export class CenteredRectangleInstance implements Instance {
    label: string;
    shape_type: string = "centeredRectangle";
    points: Point[] = [];
    max_points: number = 3;
    instanceId?: string;

    constructor(label: string, instanceId?: string) {
        this.label = label;
        this.instanceId = instanceId;
    }

    shape(): Point[] {
        if (this.points.length < 3) {
            return this.points;
        }

        const vertex_a = this.points[0];
        const vertex_b = this.points[1];
        const vertex_c = this.points[2];

        const min_x = Math.min(vertex_a.pixelX, vertex_b.pixelX);
        const min_y = Math.min(vertex_a.pixelY, vertex_b.pixelY);
        const max_x = Math.max(vertex_a.pixelX, vertex_b.pixelX);
        const max_y = Math.max(vertex_a.pixelY, vertex_b.pixelY);

        const is_visible = vertex_c.visible;

        if (is_visible) {
            return [
                { pixelX: min_x, pixelY: min_y, obscured: false, visible: true },
                { pixelX: max_x, pixelY: min_y, obscured: false, visible: true },
                { pixelX: max_x, pixelY: max_y, obscured: false, visible: true },
                { pixelX: min_x, pixelY: max_y, obscured: false, visible: true },
                {
                    pixelX: vertex_c.pixelX,
                    pixelY: vertex_c.pixelY,
                    obscured: vertex_c.obscured,
                    visible: vertex_c.visible,
                },
                { pixelX: min_x, pixelY: min_y, obscured: false, visible: true },
                { pixelX: max_x, pixelY: min_y, obscured: false, visible: true },
                {
                    pixelX: vertex_c.pixelX,
                    pixelY: vertex_c.pixelY,
                    obscured: vertex_c.obscured,
                    visible: vertex_c.visible,
                },
                { pixelX: max_x, pixelY: max_y, obscured: false, visible: true },
                { pixelX: min_x, pixelY: max_y, obscured: false, visible: true },
                { pixelX: min_x, pixelY: min_y, obscured: false, visible: true },
            ];
        } else {
            return [
                { pixelX: min_x, pixelY: min_y, obscured: false, visible: true },
                { pixelX: max_x, pixelY: min_y, obscured: false, visible: true },
                { pixelX: max_x, pixelY: max_y, obscured: false, visible: true },
                { pixelX: min_x, pixelY: max_y, obscured: false, visible: true },
            ];
        }
    }

    area(): number | undefined {
        if (this.shape().length < 5) return 0;

        let x = this.shape()[1].pixelX - this.shape()[0].pixelX;
        let y = this.shape()[2].pixelY - this.shape()[1].pixelY;
        return x * y;
    }
}

export class PolygonInstance implements Instance {
    label: string;
    shape_type: string = "polygon";
    points: Point[] = [];
    max_points: number = Infinity;

    constructor(label: string) {
        this.label = label;
    }

    shape(): Point[] {
        return this.points;
    }

    area(): number | undefined {
        return undefined;
    }
}

export class QuadrilateralInstance implements Instance {
    label: string;
    shape_type: string = "quadrilateral";
    points: Point[] = [];
    max_points: number = 4;

    constructor(label: string) {
        this.label = label;
    }

    shape(): Point[] {
        return this.points;
    }

    area(): number | undefined {
        return undefined;
    }
}

export class TriangleInstance implements Instance {
    label: string;
    shape_type: string = "triangle";
    points: Point[] = [];
    max_points: number = 3;

    constructor(label: string) {
        this.label = label;
    }

    shape(): Point[] {
        return this.points;
    }

    area(): number | undefined {
        return undefined;
    }
}

export class LineInstance implements Instance {
    label: string;
    shape_type: string = "line";
    points: Point[] = [];
    max_points: number = 2;

    constructor(label: string) {
        this.label = label;
    }

    shape(): Point[] {
        return this.points;
    }

    area(): number | undefined {
        return undefined;
    }
}

export interface ObjectDetectionAnswer {
    instances: Instance[];
}

export function createInstance(description: ObjectDetectionDescription): Instance {
    if (description.shape_type === "rectangle" || description.shape_type === "circle") {
        // (pedro.castro) we accept circle here because on torch_ml we might modify it
        // and then reupload it. If this ever fails here then we need to create a circle
        // specifc instance
        return new BoundingBoxInstance(description.label);
    } else if (description.shape_type === "centeredRectangle") {
        return new CenteredRectangleInstance(description.label, description.instanceId);
    } else if (description.shape_type === "polygon") {
        return new PolygonInstance(description.label);
    } else if (description.shape_type === "quadrilateral") {
        return new QuadrilateralInstance(description.label);
    } else if (description.shape_type === "triangle") {
        return new TriangleInstance(description.label);
    } else if (description.shape_type === "line") {
        return new LineInstance(description.label);
    } else {
        console.warn("unrecognised instance type " + description.shape_type);
        return new UndefinedInstance();
    }
}

// Method for turning JSON object into Instance object
// Is there a better way of doing this?
export function deserialiseInstance(json_instance: Instance): Instance {
    const instance = createInstance({
        label: json_instance.label,
        shape_type: json_instance.shape_type,
        instanceId: json_instance.instanceId,
    });
    for (const v of json_instance.points) {
        const point: Point = {
            pixelX: v.pixelX,
            pixelY: v.pixelY,
            obscured: v.obscured,
            visible: v.visible,
        };
        instance.points.push(point);
    }
    return instance;
}

export function deserialiseInstances(objects: ObjectDetectionDescription[], instances: Instance[]) {
    instances = instances.filter((i: Instance) =>
        objects.some((o: ObjectDetectionDescription) => o.label === i.label)
    );
    const instanceObjects = instances.map(
        (i: Instance) =>
            objects.find(
                (o: ObjectDetectionDescription) => o.label === i.label
            ) as ObjectDetectionDescription
    );

    const outputs = [];

    for (let idx = 0; idx < instances.length; ++idx) {
        const instance = instances[idx];
        const deserialized_instance = deserialiseInstance(instance);
        if (deserialized_instance.shape_type !== "undefined") {
            outputs.push(deserialized_instance);
        }
    }

    return [outputs, instanceObjects];
}

export function objectColor(description: ObjectDetectionDescription) {
    // Allowed colours: "red","orange","yellow","olive","green","teal","blue","violet","purple",
    // "pink","brown","grey","black","facebook","google plus","instagram","linkedin","twitter","vk","youtube"
    const { label } = description;
    if (label === "person") {
        return "green";
    } else if (label === "ball") {
        return "blue";
    } else if (label === "crowd") {
        return "red";
    } else if (label === "bat") {
        return "violet";
    } else if (label === "stick") {
        return "teal";
    } else if (label === "glove") {
        return "grey";
    } else if (label === "racket") {
        return "orange";
    } else if (label === "pitchline") {
        return "pink";
    } else if (label === "downmarker") {
        return "yellow";
    } else if (label === "ball mask") {
        return "brown";
    } else if (label === "digit0") {
        return "violet";
    } else if (label === "digit1") {
        return "teal";
    } else if (label === "digit2") {
        return "grey";
    } else if (label === "digit3") {
        return "orange";
    } else if (label === "digit4") {
        return "purple";
    } else if (label === "digit5") {
        return "brown";
    } else if (label === "arrow") {
        return "blue";
    } else if (label === "topBaseline" || label === "bottomBaseline") {
        return "orange";
    } else if (label === "topCenterMark" || label === "bottomCenterMark") {
        return "blue";
    } else if (label === "topServiceLine" || label === "bottomServiceLine") {
        return "violet";
    } else if (label === "net") {
        return "blue";
    } else if (label === "centerServiceLine") {
        return "orange";
    } else if (label === "leftDoublesSideLine" || label === "rightDoublesSideLine") {
        return "teal";
    } else if (label === "leftSinglesSideLine" || label === "rightSinglesSideLine") {
        return "yellow";
    } else if (
        label === "smallRectLeftTop" ||
        label === "smallRectRightBottom" ||
        label === "bigRectLeftMain" ||
        label === "bigRectRightMain"
    ) {
        return "orange";
    } else if (
        label === "sideLineLeft" ||
        label === "smallRectRightMain" ||
        label === "circleLeft"
    ) {
        return "yellow";
    } else if (
        label === "sideLineRight" ||
        label === "smallRectLeftMain" ||
        label === "circleRight"
    ) {
        return "blue";
    } else if (label === "bigRectRightTop" || label === "bigRectLeftBottom") {
        return "red";
    } else if (label === "middleLine") {
        return "brown";
    } else if (label === "circleCentral") {
        return "teal";
    } else if (
        label === "smallRectLeftBottom" ||
        label === "bigRectLeftTop" ||
        label === "sideLineTop"
    ) {
        return "purple";
    } else if (
        label === "smallRectRightTop" ||
        label === "bigRectRightBottom" ||
        label === "sideLineBottom"
    ) {
        return "pink";
    } else if (label.includes("goalRight")) {
        return "purple";
    } else if (label.includes("goalLeft")) {
        return "pink";
    } else if (label.includes("Line") || label.includes("HashMarks")) {
        if (label.includes("Left")) {
            if (label.includes("5")) {
                return "blue";
            } else return "yellow";
        } else if (label.includes("Right")) {
            if (label.includes("5")) {
                return "violet";
            } else return "orange";
        } else return "teal";
    }

    console.warn("unrecognised object label " + label);
    throw new AssertionError();
}

export function objectImage(description: ObjectDetectionDescription) {
    const { label } = description;
    if (label === "person") {
        return "/object_images/combined_person.png";
    } else if (label === "ball") {
        return "/object_images/combined_ball3.png";
    } else if (label === "crowd") {
        return "";
    } else if (label === "bat") {
        return "";
    } else if (label === "stick") {
        return "";
    } else if (label === "glove") {
        return "";
    } else if (label === "racket") {
        return "";
    } else if (label === "pitchline") {
        return "";
    } else if (label === "downmarker") {
        return "/object_images/combined_downmarker3.png";
    } else if (label === "ball mask") {
        return "";
    } else if (
        label === "digit0" ||
        label === "digit1" ||
        label === "digit2" ||
        label === "digit3" ||
        label === "digit4" ||
        label === "digit5"
    ) {
        return "";
    } else if (label === "arrow") {
        return "";
    } else if (
        label.includes("Baseline") ||
        label.includes("CenterMark") ||
        label.includes("ServiceLine") ||
        label.includes("SideLine") ||
        label.includes("net")
    ) {
        return "";
    } else if (
        label.includes("bigRect") ||
        label.includes("circle") ||
        label.includes("goal") ||
        label.includes("middleLine") ||
        label.includes("smallRect") ||
        label.includes("sideLine")
    ) {
        return "";
    } else if (label.includes("Line") || label.includes("HashMarks")) {
        return "";
    }

    console.warn("unrecognised object label " + label);
    throw new AssertionError();
}

export function objectDescription(description: ObjectDetectionDescription) {
    const { label } = description;
    if (label === "person") {
        return "Human in the pitch";
    } else if (label === "ball") {
        return "Any sports ball";
    } else if (label === "crowd") {
        return "Areas in which Human detections are not relevant";
    } else if (label === "bat") {
        return "Baseball bat";
    } else if (label === "stick") {
        return "Hockey stick";
    } else if (label === "glove") {
        return "Baseball glove";
    } else if (label === "racket") {
        return "Tennis racket";
    } else if (label === "pitchline") {
        return "Pitch line";
    } else if (label === "downmarker") {
        return "Down markers (chain crew's signal poles)";
    } else if (label === "ball mask") {
        return "Areas containing balls not used in match play";
    } else if (
        label === "digit0" ||
        label === "digit1" ||
        label === "digit2" ||
        label === "digit3" ||
        label === "digit4" ||
        label === "digit5"
    ) {
        return "NFL pitch digit";
    } else if (label === "arrow") {
        return "NFL pitch arrow";
    } else if (
        label.includes("Baseline") ||
        label.includes("CenterMark") ||
        label.includes("ServiceLine") ||
        label.includes("SideLine") ||
        label.includes("net")
    ) {
        return "Tennis court line";
    } else if (
        label.includes("bigRect") ||
        label.includes("circle") ||
        label.includes("goal") ||
        label.includes("middleLine") ||
        label.includes("smallRect") ||
        label.includes("sideLine")
    ) {
        return "Football pitch lines";
    } else if (label.includes("Line") || label.includes("HashMarks")) {
        return "NFL pitch line";
    }

    console.warn("unrecognised object label " + label);
    throw new AssertionError();
}

export interface Definition {
    type: "ObjectDetection";
    name: string;
    description: string;
    expectedTime: number;
    bucketName: string;
    objects: ObjectDetectionDescription[];
}

// Alex: is this needed?
export interface DefinitionJSON extends Definition {}
