import { observer } from "mobx-react";
import React from "react";
import { Modal } from "semantic-ui-react";
import { UserAuthStore } from "../../store/UserAuth";

const SESSION_WARNING_TIME = 13 * 60 * 1000; // 13 minutes
export const SESSION_LOGOUT_TIME = 15 * 60 * 1000; // 15 minutes
const COUNTDOWN_DURATION = 120; // seconds

interface State {
    showTimeoutWarning: boolean;
    countdown: number;
    lastInteractionTime: number;
}

interface Props {
    store: UserAuthStore;
}

@observer
class SessionTimer extends React.Component<Props, State> {
    store: UserAuthStore;
    sessionCheckInterval: NodeJS.Timeout | undefined;

    constructor(props: Props) {
        super(props);
        this.store = props.store;
        this.state = {
            showTimeoutWarning: false,
            countdown: COUNTDOWN_DURATION,
            lastInteractionTime: Date.now(),
        };
    }

    componentDidMount() {
        // Debounce the mousemove listener to only fire after some delay to prevent excess calls
        window.addEventListener("mousemove", this.debounce(this.handleUserActivity));
        window.addEventListener("keydown", this.handleUserActivity);

        // Check every second for session timeout
        this.sessionCheckInterval = setInterval(this.checkSessionTimeout, 1000);
    }

    componentWillUnmount() {
        window.removeEventListener("mousemove", this.debounce(this.handleUserActivity));
        window.removeEventListener("keydown", this.handleUserActivity);
        if (this.sessionCheckInterval) clearInterval(this.sessionCheckInterval);
    }

    debounce = (func: CallableFunction, timeout: number = 200) => {
        let timer: NodeJS.Timer;
        return (...args: any[]) => {
            clearTimeout(timer);
            timer = setTimeout(() => {
                func(this, args);
            }, timeout);
        };
    };

    handleUserActivity = () => {
        this.setState({
            lastInteractionTime: Date.now(),
            showTimeoutWarning: false,
            countdown: COUNTDOWN_DURATION,
        });
    };

    checkSessionTimeout = () => {
        const timeSinceLastInteraction = Date.now() - this.state.lastInteractionTime;

        // Show warning at 13 minutes
        if (timeSinceLastInteraction >= SESSION_WARNING_TIME) {
            this.setState({
                showTimeoutWarning: true,
                countdown:
                    COUNTDOWN_DURATION -
                    Math.floor((timeSinceLastInteraction - SESSION_WARNING_TIME) / 1000),
            });
        }
        if (timeSinceLastInteraction >= SESSION_LOGOUT_TIME) {
            this.store.logOut();
        }
    };

    render() {
        const { showTimeoutWarning, countdown } = this.state;

        return (
            showTimeoutWarning && (
                <Modal open={showTimeoutWarning} size="small">
                    <Modal.Header>Session Timeout Warning</Modal.Header>
                    <Modal.Content>
                        <p>Your session is about to expire due to inactivity.</p>
                        <p>Move your mouse or press any key to extend your session.</p>
                        <p>Logout in: {countdown} seconds</p>
                    </Modal.Content>
                </Modal>
            )
        );
    }
}

export default SessionTimer;
