import React from "react";
import { inject } from "mobx-react";

import { Header, Grid, Message, Button, Segment, Icon } from "semantic-ui-react";
import {
    ObjectDetectionDescription,
    Instance,
    createInstance,
    objectColor,
} from "../../../models/model_types/object_detection";
import { Mode } from "./Annotation";
import { Image, DrawingInstance, InstanceGroup } from "./Image";
import { Image as ImageModel } from "../../../models/Image";
import { FrameSelect } from "../../helpers/FrameSelect";
import { ProvidedAppStore } from "../../../store/AppStore";
import { newInstanceOnClick } from "../../media/annotation/ObjectDetection";
import { InstanceInfo } from "../../media/MediaAnnotation";

interface NewInstanceProps {
    updatedInstances: (instances: Instance[], mode: Mode) => void;
    definition: ObjectDetectionDescription;
    instances: Instance[];
    objects: ObjectDetectionDescription[];
    image: ImageModel;
}

class State {
    instances: Instance[] = [];
    bucketPath: string = "";
}
type Props = NewInstanceProps & ProvidedAppStore;
@inject("store")
export class NewInstance extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        let instances = this.props.instances.slice();

        const { definition, image } = this.props;
        instances.push(createInstance(definition));

        this.state = { instances, bucketPath: image.bucketPath };

        this.updatedFrame = this.updatedFrame.bind(this);
        this.onClick = this.onClick.bind(this);
        this.undo = this.undo.bind(this);
        this.done = this.done.bind(this);
    }

    componentDidUpdate(previousProps: Props) {
        if (previousProps !== this.props) {
            this.setState({ bucketPath: this.props.image.bucketPath });
        }
    }

    updatedFrame(value: string) {
        this.setState({ bucketPath: value });
    }

    undo() {
        let { instances } = this.state;
        let instance = instances[instances.length - 1];

        instance.points = instance.points.slice(0, -1);

        if (instance.points.length === 0) {
            instances = instances.slice(0, -1);
            this.props.updatedInstances(instances, Mode.Edit);
        }

        this.setState({ instances });
    }

    done() {
        let { instances } = this.state;
        this.props.updatedInstances(instances, Mode.Edit);
    }

    onClick(button: number, x: number, y: number) {
        let { instances } = this.state;
        let instance = instances[instances.length - 1];

        newInstanceOnClick(
            button,
            x,
            y,
            { instance: instance } as InstanceInfo,
            () => this.setState({ instances }),
            () => this.done()
        );
    }

    render() {
        const { image, definition, objects } = this.props;
        const { instances, bucketPath } = this.state;
        const instanceObjects = instances.map(
            (i: Instance) =>
                objects.find(
                    (o: ObjectDetectionDescription) => o.label === i.label
                ) as ObjectDetectionDescription
        );

        const drawingInstances = instances.map((i: Instance): DrawingInstance => {
            return { instance: i, activeVertex: -1 };
        });

        const instanceGroups: InstanceGroup[] = [];
        for (let idx = 0; idx < instanceObjects.length; ++idx) {
            const object = instanceObjects[idx];
            const { label } = object;
            const color = objectColor(object);
            instanceGroups.push({
                color: color,
                instances: drawingInstances.filter(
                    (elem: DrawingInstance) => elem.instance.label === label
                ),
            });
        }

        return (
            <Grid.Row>
                <Grid.Column width={4}>
                    <FrameSelect image={image} onSelect={this.updatedFrame} />
                    <Header
                        as="h3"
                        content={`Create New ${definition.label} Instance`}
                        subheader="Left click to add a point, right click to finish the object"
                    />
                    <Message>
                        <Message.Header>Click the next point</Message.Header>
                        <p hidden={definition.shape_type !== "centeredRectangle"}>
                            The first two points correspond to the boundig box, while the third one
                            represents the midhip
                        </p>
                        <Segment hidden={definition.shape_type !== "centeredRectangle"}>
                            {`Obscured    `}
                            <Icon name="circle" color="orange" />
                            {"   Visible"}
                            <Icon name="circle" color="green" />
                        </Segment>
                    </Message>
                    <Button labelPosition="left" icon="undo" content="undo" onClick={this.undo} />
                    <Button
                        labelPosition="left"
                        icon="thumbs up outline"
                        content="done"
                        onClick={this.done}
                        color="blue"
                    />
                </Grid.Column>
                <Grid.Column width={12}>
                    <Image
                        url={this.props.store!.hwkflowClient.getImageUrl(bucketPath)}
                        groups={instanceGroups}
                        onClick={this.onClick}
                    />
                </Grid.Column>
            </Grid.Row>
        );
    }
}
