import React from "react";
import { inject, observer } from "mobx-react";
import { produce } from "immer";
import { ProvidedAppStore } from "../../store/AppStore";
import {
    AnnotationCategories,
    AnnotationTypes,
    DEFAULT_VIDEO_FPS,
    KeypointAnnotationTypes,
    Media,
    MediaAnnotationSubmissionRequest,
    mediaIsImage,
    MediaStore,
    ObjectDetectionAnnotationTypes,
} from "../../store/MediaStore";
import {
    Button,
    Container,
    Dimmer,
    DimmerDimmable,
    Divider,
    Grid,
    Header,
    Loader,
    Modal,
    ModalActions,
    ModalContent,
} from "semantic-ui-react";
import { RouteComponentProps } from "react-router-dom";
import {
    createInstance as createObjectDetectionInstance,
    deserialiseInstance as deserialiseObjectDetectionInstance,
    ObjectDetectionAnswer,
    ObjectDetectionDescription,
    Instance as ObjectDetectionInstance,
} from "../../models/model_types/object_detection";
import {
    getFrameFromImage,
    getFramesFromVideoTrack,
    getVideoTrack,
} from "../helpers/MediaFunctions";
import { Hierarchy } from "./annotation/Hierarchy";
import { KeyPointDescription, SkeletonAnnotationAnswer } from "../../models/model_types/keypoints";
import { AnnotationControls } from "./annotation/Controls";
import {
    editInstanceOnClick as objectDetectionEditInstanceOnClick,
    newInstanceOnClick as objectDetectionNewInstanceOnClick,
} from "./annotation/ObjectDetection";
import { AnnotationCanvas } from "./annotation/AnnotationCanvas";
import { v4 as uuidv4 } from "uuid";
import {
    editInstanceOnClick as keypointsEditInstanceOnClick,
    KeypointInstance,
    newInstanceOnClick as keypointNewInstanceOnClick,
} from "./annotation/Keypoints";
import { Toast, ToastPayload } from "../helpers/Toast";
import { MouseButtons } from "../helpers/Mouse";

export enum Mode {
    Overview,
    NewInstance,
    EditInstance,
}

export interface InstanceInfo {
    category: AnnotationCategories;
    type: AnnotationTypes;
    instance: AnyInstance;

    // note(Alex.Shaw): Not every instance type has vertices so this isnt the best place for this
    // but I dont know where else to put it.
    focussedVertexIndex?: number;
}

export type AnyInstance = KeypointInstance | ObjectDetectionInstance;

type Props = ProvidedAppStore & RouteComponentProps;

const TOAST_DATA: { [key: string]: ToastPayload } = {
    completeSaved: { message: "All annotations for frame saved successfully." },
    partialSaved: { message: "All annotations for frame saved as partial successfully." },
    failed: {
        message: "Failed to save annotations for the frame. Please try again.",
        type: "negative",
    },
};

interface MediaAnnotationPageState {
    media?: Media;
    videoPreviewModalOpen: boolean;
    timestep: number;
    frames: Map<number, ImageBitmap>;
    mode: Mode;
    unsavedChanges: boolean;

    objectDetectionInstances: Record<ObjectDetectionAnnotationTypes, ObjectDetectionInstance[]>;
    keypointInstances: Record<KeypointAnnotationTypes, KeypointInstance[]>;
    focussedInstance?: InstanceInfo;
    currentToastData?: ToastPayload;
    showToast?: boolean;
}

@inject("store")
@observer
export class MediaAnnotationPage extends React.Component<Props, MediaAnnotationPageState> {
    store: MediaStore | undefined;
    private previewElement?: HTMLVideoElement;

    constructor(props: Props) {
        super(props);
        this.store = props.store?.mediaStore;
        this.state = {
            videoPreviewModalOpen: false,
            timestep: 0,
            frames: new Map(),
            mode: Mode.Overview,
            unsavedChanges: false,

            objectDetectionInstances: { Generic: [] },
            keypointInstances: { Humans: [] },
        };

        // note(Alex.Shaw): Binding `this` to our functions allows us to
        // pass them as props directly instead of creating anonymous functions
        this.createInstance = this.createInstance.bind(this);
        this.removeInstance = this.removeInstance.bind(this);
        this.setFocussedInstance = this.setFocussedInstance.bind(this);
        this.setMode = this.setMode.bind(this);
        this.setUnsavedChanges = this.setUnsavedChanges.bind(this);

        // note(Alex.Shaw): Refresh/back protection to prevent loss of annotations
        window.onbeforeunload = (ev: BeforeUnloadEvent) => {
            if (this.state.unsavedChanges) ev.preventDefault();
        };
    }

    async componentDidMount() {
        if (!this.store) return;
        this.store.fetchAnnotationTypes();
        const mediaId = this.fetchRouteParameters().id;
        this.loadMedia(mediaId).then(() => {
            this.loadFrames();
            // note(Alex.Shaw): Setting the timestep here loads the correct annotations for the frame.
            this.setTimestep(this.state.timestep);
        });
    }

    fetchRouteParameters() {
        const { params } = this.props.match;
        const { id } = params as any;
        return { id: parseInt(id) };
    }

    async loadMedia(id: number) {
        if (!this.store || !id) return;

        const media: Media = await this.store.getMedia(id, true);
        this.setState({ media: media });
    }

    async loadFrames() {
        if (!this.store || !this.state.media) return;
        const media = this.state.media;

        let frames: Map<number, ImageBitmap> = new Map();
        const mediaUrl = this.store.getMediaUrl(media.bucketPath);
        if (mediaIsImage(media)) {
            frames.set(0, await getFrameFromImage(mediaUrl));
        } else {
            // note(Alex.Shaw): The frame extraction method we use requires
            // us to play the whole video, so we pass in a preview element
            // so that the user has a chance to watch the video instead of
            // waiting for a loading screen. We skip this for images, as those
            // are near instant to process.
            const track = await getVideoTrack(mediaUrl, this.previewElement!);
            const takeEvery = this.calculateFrameGapBetweenAnnotations();
            const keepFrames = this.findTimestepsWithExistingAnnotations();
            frames = await getFramesFromVideoTrack(track, takeEvery, keepFrames);
        }

        this.setState({
            frames: frames,
        });
    }

    calculateFrameGapBetweenAnnotations() {
        const minimumTimeBetweenFramesMs = 300;
        const variationTimeBetweenFramesMs = 100;

        let frameTime = 1000 / (this.state.media!.fps || DEFAULT_VIDEO_FPS);
        let frameDelta = Math.ceil(
            (Math.random() * variationTimeBetweenFramesMs + minimumTimeBetweenFramesMs) / frameTime
        );
        return frameDelta;
    }

    findTimestepsWithExistingAnnotations(): number[] {
        if (!this.state.media) return [];
        return this.state.media?.annotations.map((annotation) => annotation.timestep);
    }

    setTimestep(timestep: number) {
        this.setState({
            ...this.state,
            timestep: timestep,
            objectDetectionInstances: {
                Generic: this.getInstancesForAnnotationType(
                    AnnotationCategories.ObjectDetection,
                    ObjectDetectionAnnotationTypes.Generic,
                    deserialiseObjectDetectionInstance,
                    timestep
                ),
            },
            keypointInstances: {
                Humans: this.getInstancesForAnnotationType(
                    AnnotationCategories.Keypoints,
                    KeypointAnnotationTypes.Humans,
                    (instance: AnyInstance) => instance,
                    timestep
                ),
            },
        });
    }

    setFocussedInstance(instanceInfo: InstanceInfo) {
        this.setState({ focussedInstance: instanceInfo });
    }

    setUnsavedChanges(unsavedChanges: boolean) {
        this.setState({ unsavedChanges: unsavedChanges });
    }

    getInstancesForAnnotationType(
        category: AnnotationCategories,
        type: AnnotationTypes,
        deserialiseFunction: CallableFunction,
        timestep?: number
    ) {
        let annotations = this.state.media?.annotations.filter((ann) => {
            const matches = ann.annotationCategory === category && ann.annotationType === type;
            if (timestep !== undefined) return ann.timestep === timestep && matches;
            return matches;
        });

        if (!annotations) return [];
        // The only way we can have more than 1 latest annotation for a given category, type and timestep
        // is if one is complete and one is partial. In this case, the partial annotation will be newer,
        // and what we want to annnotate/edit.
        if (annotations.length > 1) {
            annotations = annotations.filter((annotation) => annotation.isPartial);
        }

        return annotations
            .map((ann) => ann.answerData.instances as any)
            .reduce((accumulator, value) => accumulator.concat(value), [])
            .map((instance: AnyInstance) => deserialiseFunction(instance));
    }

    getAdjacentAnnotatableTimesteps(timestep: number, annotatableTimesteps: number[]) {
        const next = Math.min(...annotatableTimesteps.filter((i) => i > timestep));
        const last = Math.max(...annotatableTimesteps.filter((i) => i < timestep));

        return { previous: last, next: next };
    }

    // Create Instances

    createObjectDetectionInstanceAndUpdateState(
        type: ObjectDetectionAnnotationTypes,
        metadata: ObjectDetectionDescription
    ) {
        let newInstance: ObjectDetectionInstance | undefined;
        const addedInstanceState = produce(this.state.objectDetectionInstances, (draft) => {
            if (type === ObjectDetectionAnnotationTypes.Generic) {
                metadata.instanceId = `${Date.now()}_${uuidv4()}`;
                newInstance = createObjectDetectionInstance(metadata);
                draft.Generic.unshift(newInstance);
            }
        });
        this.setState({ objectDetectionInstances: addedInstanceState });
        return newInstance;
    }

    createKeypointsInstanceAndUpdateState(type: KeypointAnnotationTypes, metadata: any) {
        let newInstance: KeypointInstance | undefined;
        const addedInstanceState = produce(this.state.keypointInstances, (draft) => {
            if (type === KeypointAnnotationTypes.Humans) {
                newInstance = new KeypointInstance(metadata.parentInstanceId);
                draft.Humans.unshift(newInstance);
            }
        });
        this.setState({ keypointInstances: addedInstanceState });
        return newInstance;
    }

    createInstance(category: AnnotationCategories, type: AnnotationTypes, metadata: any) {
        let newInstance: AnyInstance | undefined;
        if (category === AnnotationCategories.ObjectDetection) {
            newInstance = this.createObjectDetectionInstanceAndUpdateState(
                type as ObjectDetectionAnnotationTypes,
                metadata as ObjectDetectionDescription
            );
        } else if (category === AnnotationCategories.Keypoints) {
            newInstance = this.createKeypointsInstanceAndUpdateState(
                type as KeypointAnnotationTypes,
                metadata
            );
        }

        if (newInstance) {
            this.setFocussedInstance({ category: category, type: type, instance: newInstance });
            this.setMode(Mode.NewInstance);
        }
    }

    // Remove Instances

    removeObjectDetectionInstance(
        type: ObjectDetectionAnnotationTypes,
        instance: ObjectDetectionInstance
    ) {
        const removedInstanceState = produce(this.state.objectDetectionInstances, (draft) => {
            if (type === ObjectDetectionAnnotationTypes.Generic) {
                draft.Generic = draft.Generic.filter((item) => item !== instance);
            }
        });
        this.setState({ objectDetectionInstances: removedInstanceState });
    }

    removeKeypointInstance(type: KeypointAnnotationTypes, instance: KeypointInstance) {
        const removedInstanceState = produce(this.state.keypointInstances, (draft) => {
            if (type === KeypointAnnotationTypes.Humans) {
                // Not sure why the object comparison works for objects but not keypoints
                draft.Humans = draft.Humans.filter(
                    (item) => JSON.stringify(item) !== JSON.stringify(instance)
                );
            }
        });
        this.setState({ keypointInstances: removedInstanceState });
    }

    removeInstance(category: AnnotationCategories, type: AnnotationTypes, instance: AnyInstance) {
        if (category === AnnotationCategories.ObjectDetection) {
            this.removeObjectDetectionInstance(
                type as ObjectDetectionAnnotationTypes,
                instance as ObjectDetectionInstance
            );
        } else if (category === AnnotationCategories.Keypoints) {
            this.removeKeypointInstance(
                type as KeypointAnnotationTypes,
                instance as KeypointInstance
            );
        }
    }

    // Save Annotations

    async saveAnnotationForTypeAndCategory(
        category: AnnotationCategories,
        type: AnnotationTypes,
        answerData: ObjectDetectionAnswer | SkeletonAnnotationAnswer,
        isPartial: boolean
    ): Promise<Boolean> {
        if (!this.state.media) return false;
        if (!this.store) return false;

        const annotationRequest: MediaAnnotationSubmissionRequest = {
            mediaId: this.state.media?.id,
            answerData: answerData,
            annotationCategory: category,
            annotationType: type,
            timestep: this.state.timestep,
            isPartial: isPartial,
            // TODO: MLT-2416: Time Tracking
            completionTime: 0,
            comment: "",
        };

        return await this.store?.submitAnnotation(annotationRequest);
    }

    async saveAllAnnotationsForFrame(isPartial: boolean) {
        if (!this.state.media) return;

        const objectSaveSuccessful = await this.saveAnnotationForTypeAndCategory(
            AnnotationCategories.ObjectDetection,
            ObjectDetectionAnnotationTypes.Generic,
            { instances: this.state.objectDetectionInstances.Generic },
            isPartial
        );

        const keypointSaveSuccessful = await this.saveAnnotationForTypeAndCategory(
            AnnotationCategories.Keypoints,
            KeypointAnnotationTypes.Humans,
            { instances: this.state.keypointInstances.Humans },
            isPartial
        );

        const toastState =
            objectSaveSuccessful && keypointSaveSuccessful
                ? isPartial
                    ? "partialSaved"
                    : "completeSaved"
                : "failed";

        this.setState({ showToast: true, currentToastData: TOAST_DATA[toastState] });
        this.setUnsavedChanges(false);
        this.setMode(Mode.Overview);
        // note(Alex.Shaw): We reload the media here so all annotations are on the media object.
        this.loadMedia(this.state.media.id);
    }

    setMode(mode: Mode) {
        this.setState({ mode: mode });
        if (mode === Mode.Overview) this.setState({ focussedInstance: undefined });
    }

    // Instance Completion Callbacks

    instanceCompleteCallback() {
        this.setUnsavedChanges(true);
        this.setMode(Mode.Overview);
    }

    objectDetectionInstanceCompleteCallback() {
        const instance = this.state.focussedInstance!.instance as ObjectDetectionInstance;
        this.instanceCompleteCallback();

        const children = this.state.keypointInstances.Humans.filter(
            (keypointInstance) => keypointInstance.parentInstanceId === instance.instanceId
        );
        if (children.length === 0 && instance.label === "person") {
            this.createInstance(AnnotationCategories.Keypoints, KeypointAnnotationTypes.Humans, {
                parentInstanceId: instance.instanceId,
            });
        }
    }

    // Annotation Canvas Click Handlers

    overviewOnClickHandler(button: number, x: number, y: number) {
        // On left click, find the closest instance within some threshold
        // and set this as the focussed instance. If none found, clear
        // any focussed instances
        if (button !== MouseButtons.LEFT_CLICK) return;

        let closestInstance: InstanceInfo | undefined;
        let currentClosestDistance: number = Number.MAX_VALUE;
        const maxDistanceThreshold: number = 300;
        this.state.objectDetectionInstances.Generic.forEach((instance) => {
            instance.points.forEach((point) => {
                const distance = Math.sqrt(
                    Math.pow(x - point.pixelX, 2) + Math.pow(y - point.pixelY, 2)
                );
                if (distance < currentClosestDistance && distance < maxDistanceThreshold) {
                    closestInstance = {
                        category: AnnotationCategories.ObjectDetection,
                        type: ObjectDetectionAnnotationTypes.Generic,
                        instance: instance,
                    };
                    currentClosestDistance = distance;
                }
            });
        });

        if (!closestInstance) {
            this.setMode(Mode.Overview);
        } else {
            this.setFocussedInstance(closestInstance);
        }
    }

    newInstanceOnClickHandler(button: number, x: number, y: number) {
        if (!this.state.focussedInstance) return;
        const category = this.state.focussedInstance.category;

        if (category === AnnotationCategories.ObjectDetection) {
            objectDetectionNewInstanceOnClick(
                button,
                x,
                y,
                this.state.focussedInstance,
                this.setFocussedInstance,
                () => this.objectDetectionInstanceCompleteCallback()
            );
        } else if (category === AnnotationCategories.Keypoints) {
            if (!this.store) return;
            const annotationType = this.store.findAnnotationType(
                AnnotationCategories.Keypoints,
                this.state.focussedInstance.type
            );
            const keypointDefinitions = annotationType!.definition as KeyPointDescription[];
            keypointNewInstanceOnClick(
                button,
                x,
                y,
                this.state.focussedInstance,
                keypointDefinitions,
                this.setFocussedInstance,
                () => this.instanceCompleteCallback()
            );
        }
    }

    editInstanceOnClickHandler(button: number, x: number, y: number) {
        if (!this.state.focussedInstance) return;
        const category = this.state.focussedInstance.category;

        if (category === AnnotationCategories.ObjectDetection) {
            objectDetectionEditInstanceOnClick(
                button,
                x,
                y,
                this.state.focussedInstance,
                this.setFocussedInstance,
                () => this.objectDetectionInstanceCompleteCallback()
            );
        } else if (category === AnnotationCategories.Keypoints) {
            if (!this.store) return;
            const annotationType = this.store.findAnnotationType(
                AnnotationCategories.Keypoints,
                this.state.focussedInstance.type
            );
            const keypointDefinitions = annotationType!.definition as KeyPointDescription[];
            keypointsEditInstanceOnClick(
                button,
                x,
                y,
                this.state.focussedInstance,
                keypointDefinitions,
                this.setFocussedInstance
            );
        }
    }

    annotationCanvasOnClick(button: number, x: number, y: number) {
        const mode = this.state.mode;

        if (mode === Mode.Overview) {
            this.overviewOnClickHandler(button, x, y);
        } else if (mode === Mode.NewInstance) {
            this.newInstanceOnClickHandler(button, x, y);
        } else if (mode === Mode.EditInstance) {
            this.editInstanceOnClickHandler(button, x, y);
        }
    }

    generateMediaControls() {
        const timestep = this.state.timestep;
        const annotatableTimesteps = Array.from(this.state.frames.keys());
        const adjacentAnnotatableTimesteps = this.getAdjacentAnnotatableTimesteps(
            timestep,
            annotatableTimesteps
        );
        const disableFrameNavigation =
            this.state.mode !== Mode.Overview || this.state.unsavedChanges;

        return (
            <Container
                style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginBottom: "8px",
                }}
            >
                {this.state.frames.size === 0 ? (
                    <Header as="h3">Extracting frames, please wait...</Header>
                ) : (
                    <>
                        <Button
                            labelPosition="left"
                            icon="left chevron"
                            content="Previous Annotatable Frame"
                            disabled={
                                this.state.timestep === annotatableTimesteps[0] ||
                                disableFrameNavigation
                            }
                            onClick={() => this.setTimestep(adjacentAnnotatableTimesteps.previous)}
                        />
                        <Button
                            labelPosition="right"
                            icon="right chevron"
                            content="Next Annotatable Frame"
                            disabled={
                                this.state.timestep ===
                                    annotatableTimesteps[annotatableTimesteps.length - 1] ||
                                disableFrameNavigation
                            }
                            onClick={() => this.setTimestep(adjacentAnnotatableTimesteps.next)}
                        />
                        <Button
                            labelPosition="left"
                            icon={"play"}
                            content={"Watch Video"}
                            onClick={() => this.setState({ videoPreviewModalOpen: true })}
                        />
                    </>
                )}
            </Container>
        );
    }

    render() {
        if (!this.state.media) return <Loader active />;
        if (!this.store) return <Loader active />;
        const media = this.state.media;
        const disableSaving = !this.state.unsavedChanges || this.state.mode !== Mode.Overview;

        return (
            <Container>
                {
                    // Preview video modal
                    !mediaIsImage(media) && (
                        <Modal
                            closeIcon
                            size="large"
                            open={this.state.videoPreviewModalOpen}
                            onClose={() => this.setState({ videoPreviewModalOpen: false })}
                        >
                            <ModalContent>
                                <video
                                    controls
                                    loop
                                    className="media-view"
                                    src={this.store!.getMediaUrl(this.state.media.bucketPath)}
                                />
                            </ModalContent>
                            <ModalActions>
                                <Button
                                    negative
                                    onClick={() => this.setState({ videoPreviewModalOpen: false })}
                                >
                                    Close
                                </Button>
                            </ModalActions>
                        </Modal>
                    )
                }
                <Header as="h2">Annotating Media {media?.id}</Header>
                <Toast
                    showToast={this.state.showToast}
                    setShowToast={(show: boolean) => {
                        this.setState({ showToast: show });
                    }}
                    {...this.state.currentToastData}
                />
                <Grid>
                    <Grid.Row>
                        <Grid.Column width={4}>
                            <DimmerDimmable as={Container} dimmed={this.state.frames.size === 0}>
                                <Dimmer inverted active={this.state.frames.size === 0} />
                                <AnnotationControls
                                    currentTimestep={this.state.timestep}
                                    mode={this.state.mode}
                                    createInstance={this.createInstance}
                                    removeInstance={this.removeInstance}
                                    updateInstance={this.setFocussedInstance}
                                    setMode={this.setMode}
                                    focussedInstanceInfo={this.state.focussedInstance}
                                />
                                <Divider />
                                <Header as="h3">Existing Annotations</Header>
                                <Hierarchy
                                    objectDetectionInstances={this.state.objectDetectionInstances}
                                    keypointInstances={this.state.keypointInstances}
                                    mode={this.state.mode}
                                    createInstance={this.createInstance}
                                    removeInstance={this.removeInstance}
                                    updateInstance={this.setFocussedInstance}
                                    setMode={this.setMode}
                                    setUnsavedChanges={this.setUnsavedChanges}
                                    focussedInstanceInfo={this.state.focussedInstance}
                                />
                            </DimmerDimmable>
                        </Grid.Column>
                        <Grid.Column width={12}>
                            {!mediaIsImage(media) && this.generateMediaControls()}
                            {!mediaIsImage(media) && this.state.frames.size === 0 && (
                                <video
                                    className="media-view"
                                    ref={(child: HTMLVideoElement) => (this.previewElement = child)}
                                />
                            )}
                            {this.state.frames.size > 0 && (
                                <Container style={{ position: "relative" }}>
                                    <AnnotationCanvas
                                        image={this.state.frames.get(this.state.timestep)!}
                                        objectDetectionInstances={
                                            this.state.objectDetectionInstances
                                        }
                                        keypointInstances={this.state.keypointInstances}
                                        annotationTypes={this.store.annotationTypes}
                                        focussedInstance={this.state.focussedInstance}
                                        onClick={(button: number, x: number, y: number) => {
                                            this.annotationCanvasOnClick(button, x, y);
                                        }}
                                    />
                                </Container>
                            )}
                            <Container
                                style={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                }}
                            >
                                <Button
                                    color="yellow"
                                    disabled={disableSaving}
                                    onClick={() => this.saveAllAnnotationsForFrame(true)}
                                >
                                    Save Partial Annotation
                                </Button>
                                <Button
                                    color="green"
                                    disabled={disableSaving}
                                    onClick={() => this.saveAllAnnotationsForFrame(false)}
                                >
                                    Save Annotation
                                </Button>
                            </Container>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Container>
        );
    }
}
