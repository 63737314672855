import { KeyPointDescription, Instance, Keypoint } from "../../../models/model_types/keypoints";
import { InstanceGroup as KeypointInstanceGroup } from "../../annotation/KeyPoint/Image";
import { toSVGColor } from "../../annotation/ObjectDetection/Image";
import { hexToRgba } from "../../helpers/AnnotationInfo";
import {
    findClosestPointIndex,
    MouseButtons,
    POINT_DELETION_DISTANCE_THRESHOLD,
} from "../../helpers/Mouse";
import { TrackedCTX } from "../../helpers/TrackedSVG";
import { InstanceInfo } from "../MediaAnnotation";

// note(Alex.Shaw): We create a class here to represent KeypointInstances
// as if we create an instance with {} and cast it, when accessed through state it
// is read only.
export class KeypointInstance implements Instance {
    parentInstanceId?: string | undefined;
    keypoints: Keypoint[];

    constructor(parentInstanceId?: string) {
        this.parentInstanceId = parentInstanceId;
        this.keypoints = [];
    }
}

export function newInstanceOnClick(
    button: number,
    x: number,
    y: number,
    instanceInfo: InstanceInfo,
    keypointDefinitions: KeyPointDescription[],
    updateInstance: (instanceInfo: InstanceInfo) => void,
    onComplete: () => void
) {
    let instance = instanceInfo.instance as KeypointInstance;
    const currentKeypoint = keypointDefinitions[instance.keypoints.length];

    instance.keypoints.push({
        string: currentKeypoint.name,
        pixelX: x,
        pixelY: y,
        visible: button < MouseButtons.RIGHT_CLICK,
        obscured: button === MouseButtons.MIDDLE_CLICK,
    });

    updateInstance({
        ...instanceInfo,
        instance: instance,
    });

    if (instance.keypoints.length === keypointDefinitions.length) onComplete();
}

export function editInstanceOnClick(
    button: number,
    x: number,
    y: number,
    instanceInfo: InstanceInfo,
    keypointDefinitions: KeyPointDescription[],
    updateInstance: (instanceInfo: InstanceInfo) => void
) {
    let instance = instanceInfo.instance as KeypointInstance;
    // note(Alex.Shaw): We explicitly check for undefined here as 0 is a "falsy" value
    const focussedVertexIndexExists = instanceInfo.focussedVertexIndex !== undefined;

    if (button === MouseButtons.RIGHT_CLICK && !focussedVertexIndexExists) {
        let { pointDistance, closestPointIdx } = findClosestPointIndex(x, y, instance.keypoints);
        if (pointDistance > POINT_DELETION_DISTANCE_THRESHOLD || closestPointIdx < 0) return;
        instance.keypoints.splice(closestPointIdx, 1);
        updateInstance({
            ...instanceInfo,
            instance: instance,
            focussedVertexIndex: closestPointIdx,
        });
    } else if (button < MouseButtons.RIGHT_CLICK && focussedVertexIndexExists) {
        const currentKeypoint = keypointDefinitions[instanceInfo.focussedVertexIndex!];
        const newPoint = {
            string: currentKeypoint.name,
            pixelX: x,
            pixelY: y,
            visible: button < MouseButtons.RIGHT_CLICK,
            obscured: button === MouseButtons.MIDDLE_CLICK,
        };
        // note(Alex.Shaw): Inserts the item at the given index
        instance.keypoints.splice(instanceInfo.focussedVertexIndex!, 0, newPoint);
        updateInstance({ ...instanceInfo, instance: instance, focussedVertexIndex: undefined });
    }
}

export function createKeypointInstanceDrawingGroup(
    instances: KeypointInstance[],
    focussedInstance?: KeypointInstance
): KeypointInstanceGroup[] {
    const nonActiveInstances = instances.filter((item) => item !== focussedInstance);
    const activeInstances = instances.filter((item) => item === focussedInstance);
    let groups = [
        {
            color: "pink",
            instances: nonActiveInstances,
        },
        {
            color: "yellow",
            instances: activeInstances,
        },
    ];
    return groups;
}

export function drawKeypointInstance(
    ctx: CanvasRenderingContext2D,
    trackedCtx: TrackedCTX,
    keypoints: Keypoint[],
    definition: KeyPointDescription[],
    color: string,
    imageScale: number,
    isCrossValidation: boolean,
    opacity: number
) {
    const limbColor = toSVGColor(color);
    const obscuredColor = isCrossValidation ? color : "blue";
    for (const fromKeypoint of keypoints) {
        if (!fromKeypoint.visible) {
            continue;
        }
        let jointColor = fromKeypoint.obscured ? toSVGColor(obscuredColor) : limbColor;
        const obscuredResize = fromKeypoint.obscured ? 2 : 1;
        jointColor = hexToRgba(jointColor, opacity);
        const keypointDefinition = definition.find(
            (i: KeyPointDescription) => i.name === fromKeypoint.string
        );

        if (keypointDefinition == null) {
            console.warn("failed to find keypoint in definition! : " + fromKeypoint.string);
            continue;
        }

        const fromX = fromKeypoint.pixelX * imageScale;
        const fromY = fromKeypoint.pixelY * imageScale;

        ctx.beginPath();
        ctx.arc(fromX, fromY, (obscuredResize * 4) / trackedCtx.getScale(), 0, 2 * Math.PI);
        ctx.fillStyle = jointColor;
        ctx.fill();
        ctx.lineWidth = 2 / trackedCtx.getScale();
        ctx.strokeStyle = hexToRgba("#003300", opacity);
        ctx.stroke();

        const dynamicFontSize = 10 / trackedCtx.getScale();
        const defaultFontSize = 3;
        const fontSize = dynamicFontSize < defaultFontSize ? dynamicFontSize : defaultFontSize;

        ctx.beginPath();
        ctx.font = `${fontSize}px Arial`;
        ctx.fillText(fromKeypoint.string, fromX, fromY);
        ctx.stroke();

        for (const toName of keypointDefinition.connections) {
            const toKeypoint = keypoints.find((i: Keypoint) => i.string === toName);

            if (toKeypoint == null) {
                continue;
            }

            if (!toKeypoint.visible) {
                continue;
            }

            const toX = toKeypoint.pixelX * imageScale;
            const toY = toKeypoint.pixelY * imageScale;

            const object_holding_condition =
                toName.toLowerCase().includes("neck") &&
                keypointDefinition.name.toLowerCase().includes("handle");
            object_holding_condition ? ctx.setLineDash([2, 2]) : ctx.setLineDash([1, 0]);
            ctx.beginPath();
            ctx.moveTo(fromX, fromY);
            ctx.lineWidth = 3 / trackedCtx.getScale();

            if (isCrossValidation) {
                ctx.strokeStyle = jointColor;
            } else {
                if (object_holding_condition) {
                    ctx.lineWidth = 0.2; // make the line smaller
                    ctx.strokeStyle = `rgba(125, 255, 125, ${opacity})`;
                } else if ((toName + keypointDefinition.name).toLowerCase().includes("right")) {
                    ctx.strokeStyle = `rgb(255, 0, 0, ${opacity})`;
                } else if ((toName + keypointDefinition.name).toLowerCase().includes("left")) {
                    ctx.strokeStyle = `rgb(0, 0, 255, ${opacity})`;
                } else {
                    ctx.strokeStyle = `rgb(0, 255, 0, ${opacity})`;
                }
            }

            ctx.lineTo(toX, toY);
            ctx.stroke();
        }
    }
}
