import React from "react";
import { Container, Header } from "semantic-ui-react";
import { inject, observer } from "mobx-react";

import { ProvidedAppStore } from "../../../store/AppStore";
import { MediaStore, MediaTask } from "../../../store/MediaStore";
import { MediaTaskCreationForm } from "./TaskCreationForm";
import { MediaTaskList } from "./TaskList";

interface TaskPageState {
    tasks: MediaTask[];
}

@inject("store")
@observer
export class MediaTasksPage extends React.Component<ProvidedAppStore, TaskPageState> {
    store: MediaStore | undefined;

    constructor(props: ProvidedAppStore) {
        super(props);
        this.store = props.store?.mediaStore;
        this.state = { tasks: [] };
    }

    componentDidMount(): void {
        this.store?.fetchAnnotationTypes();
        this.fetchTasks();
    }

    fetchTasks() {
        this.store?.fetchTasks().then((tasks) => {
            tasks.sort((a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime());
            this.setState({ tasks: tasks });
        });
    }

    render() {
        return (
            <Container>
                <MediaTaskCreationForm taskCreatedCallback={() => this.fetchTasks()} />
                <Header as="h3">Media Tasks</Header>
                <MediaTaskList tasks={this.state.tasks} refreshTasks={() => this.fetchTasks()} />
            </Container>
        );
    }
}
