import { observer } from "mobx-react";
import React from "react";
import { Message } from "semantic-ui-react";

export interface ToastPayload {
    type?: "positive" | "negative";
    message?: string;
}

interface ToastProps extends ToastPayload {
    setShowToast: (showToast: boolean) => void;
    showToast?: boolean;
}

const DEFAULT_TOAST_DURATION = 5000;

@observer
export class Toast extends React.Component<ToastProps, {}> {
    componentDidUpdate(
        prevProps: Readonly<ToastProps>,
        prevState: Readonly<{}>,
        snapshot?: any
    ): void {
        if (this.props.showToast && !prevProps.showToast) {
            setTimeout(() => {
                this.props.setShowToast(false);
            }, DEFAULT_TOAST_DURATION);
        }
    }

    render(): React.ReactNode {
        return this.props.showToast ? (
            <Message
                positive={this.props.type !== "negative"}
                negative={this.props.type === "negative"}
            >
                {this.props.message}
            </Message>
        ) : (
            <></>
        );
    }
}
