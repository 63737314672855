import {
    objectColor,
    Instance as ObjectDetectionInstance,
    Point,
} from "../../../models/model_types/object_detection";
import { AnnotationCategories, ObjectDetectionAnnotationTypes } from "../../../store/MediaStore";
import { DrawingInstance, InstanceGroup, toSVGColor } from "../../annotation/ObjectDetection/Image";
import { TrackedCTX } from "../../helpers/TrackedSVG";
import { InstanceInfo } from "../MediaAnnotation";
import {
    findClosestPointIndex,
    MouseButtons,
    POINT_DELETION_DISTANCE_THRESHOLD,
} from "../../helpers/Mouse";
import { hexToRgba } from "../../helpers/AnnotationInfo";

export function newInstanceOnClick(
    button: number,
    x: number,
    y: number,
    instanceInfo: InstanceInfo,
    updateInstance: (instanceInfo: InstanceInfo) => void,
    onComplete: () => void
) {
    const instance = instanceInfo.instance as ObjectDetectionInstance;

    if (button < MouseButtons.RIGHT_CLICK || instance.shape_type === "centeredRectangle") {
        let is_obscured = false;
        let is_visible = true;

        if (instance.shape_type === "centeredRectangle" && instance.points.length === 2) {
            is_obscured = button === MouseButtons.MIDDLE_CLICK;
            is_visible = button < MouseButtons.RIGHT_CLICK;
        }

        instance.points.push({
            pixelX: x,
            pixelY: y,
            obscured: is_obscured,
            visible: is_visible,
        });

        updateInstance({
            category: AnnotationCategories.ObjectDetection,
            type: ObjectDetectionAnnotationTypes.Generic,
            instance: instance,
        });

        if (instance.points.length >= instance.max_points) {
            onComplete();
        }
    } else if (button === MouseButtons.RIGHT_CLICK) {
        onComplete();
    }
}

export function editInstanceOnClick(
    button: number,
    x: number,
    y: number,
    instanceInfo: InstanceInfo,
    updateInstance: (instanceInfo: InstanceInfo) => void,
    onComplete: () => void
) {
    const instance = instanceInfo.instance as ObjectDetectionInstance;
    // note(Alex.Shaw): We explicitly check for undefined here as 0 is a "falsy" value
    const focussedVertexIndexExists = instanceInfo.focussedVertexIndex !== undefined;

    // note(Alex.Shaw): Only allow one vertex at a time to be deleted, unless the shape is a polygon
    if (
        button === MouseButtons.RIGHT_CLICK &&
        (!focussedVertexIndexExists || instance.shape_type === "polygon")
    ) {
        let { pointDistance, closestPointIdx } = findClosestPointIndex(x, y, instance.points);
        if (pointDistance > POINT_DELETION_DISTANCE_THRESHOLD || closestPointIdx < 0) return;
        instance.points.splice(closestPointIdx, 1);
        updateInstance({
            ...instanceInfo,
            instance: instance,
            focussedVertexIndex: closestPointIdx,
        });
    } else if (
        button < MouseButtons.RIGHT_CLICK &&
        (focussedVertexIndexExists || instance.points.length < instance.max_points)
    ) {
        const newPoint = {
            pixelX: x,
            pixelY: y,
            obscured: button === MouseButtons.MIDDLE_CLICK,
            visible: true,
        };

        const insertionIndex = focussedVertexIndexExists
            ? instanceInfo.focussedVertexIndex!
            : instance.points.length;
        // note(Alex.Shaw): Inserts the item at the given index
        instance.points.splice(insertionIndex, 0, newPoint);
        updateInstance({ ...instanceInfo, instance: instance, focussedVertexIndex: undefined });

        if (instance.points.length >= instance.max_points) {
            onComplete();
        }
    }
}

// TODO: Add active verticies
export function createObjectDetectionInstanceDrawingGroup(
    instances: ObjectDetectionInstance[],
    focussedInstance?: ObjectDetectionInstance
): InstanceGroup[] {
    let drawingInstances = instances
        .map((instance) => {
            return { instance: instance, activeVertex: -1 } as DrawingInstance;
        })
        .map((drawingInstance) => {
            let color = objectColor(drawingInstance.instance);
            if (drawingInstance.instance === focussedInstance) color = "yellow";

            return { color: color, instances: [drawingInstance] };
        });

    return drawingInstances;
}

export function drawObjectDetectionInstance(
    ctx: CanvasRenderingContext2D,
    trackedCTX: TrackedCTX,
    instance: ObjectDetectionInstance,
    color: string,
    imageScale: number,
    activeVertex: number,
    opacity: number = 1
) {
    if (!trackedCTX) return;

    const svgColor = hexToRgba(toSVGColor(color), opacity);
    for (const fromVertex of instance.points) {
        if (!fromVertex.visible) {
            continue;
        }
        const fromX = fromVertex.pixelX * imageScale;
        const fromY = fromVertex.pixelY * imageScale;

        const jointColor = fromVertex.obscured ? toSVGColor("orange") : svgColor;
        const obscuredResize = fromVertex.obscured ? 2 : 1;

        const radius = (obscuredResize * 3) / trackedCTX.getScale();
        const lineWidth = 2 / trackedCTX.getScale();

        drawFilledCircle(ctx, fromX, fromY, radius, jointColor, lineWidth);

        const dynamicFontSize = 10 / trackedCTX.getScale();
        const defaultFontSize = 3;
        const fontSize = dynamicFontSize < defaultFontSize ? dynamicFontSize : defaultFontSize;

        ctx.beginPath();
        ctx.font = `${fontSize}px Arial`;
        ctx.stroke();
    }

    const shape = instance.shape();
    if (shape.length > 1) {
        let toVertex = shape[shape.length - 1];
        for (const fromVertex of shape) {
            const fromX = fromVertex.pixelX * imageScale;
            const fromY = fromVertex.pixelY * imageScale;

            const toX = toVertex.pixelX * imageScale;
            const toY = toVertex.pixelY * imageScale;

            ctx.beginPath();
            ctx.moveTo(fromX, fromY);
            ctx.lineWidth = 3 / trackedCTX.getScale();
            ctx.strokeStyle = svgColor;
            ctx.lineTo(toX, toY);
            ctx.stroke();

            toVertex = fromVertex;
        }
    }

    let points = instance.points;
    if (activeVertex >= 0 && 0 < instance.points.length) {
        // Note that we use the points directly here rather than the shape() method, because
        // activeVertex is an index of the points
        const thisVertex = activeVertex < instance.points.length ? activeVertex : 0;
        const prevVertex = activeVertex > 0 ? activeVertex - 1 : instance.points.length - 1;
        points = [instance.points[thisVertex], instance.points[prevVertex]];
    }
    points.forEach((point: Point) => {
        const x = point.pixelX * imageScale;
        const y = point.pixelY * imageScale;
        const radius = 5 / trackedCTX!.getScale();
        const color = point.obscured ? toSVGColor("orange") : svgColor;
        const lineWidth = 2 / trackedCTX!.getScale();

        drawFilledCircle(ctx, x, y, radius, color, lineWidth);
    });
}

export function drawFilledCircle(
    ctx: CanvasRenderingContext2D,
    x: number,
    y: number,
    radius: number,
    svgColor: string,
    lineWidth: number
) {
    ctx.beginPath();
    ctx.arc(x, y, radius, 0, 2 * Math.PI);
    ctx.fillStyle = svgColor;
    ctx.fill();
    ctx.lineWidth = lineWidth;
    ctx.strokeStyle = "#003300";
    ctx.stroke();
}
