import React from "react";
import { Button, Container, Header, Form, Loader, Input, TextArea, Popup } from "semantic-ui-react";
import { inject, observer } from "mobx-react";

import { ProvidedAppStore } from "../../../store/AppStore";
import { AnnotationType, MediaStore } from "../../../store/MediaStore";
import DayPickerInput from "react-day-picker/DayPickerInput";
import "react-day-picker/lib/style.css";
import { TaskPriority } from "../../../models/Task";
import { Toast, ToastPayload } from "../../helpers/Toast";

interface TaskCreationFormState {
    target: number;
    annotationTypes: AnnotationType[];
    deadline: Date;
    priority: TaskPriority;
    name: string;
    instructions: string;
    stadiums: string[];
    applications: string[];
    sports: string[];
    tags: string[];
    earliestUploadDate?: Date;
    latestUploadDate?: Date;

    currentToastData?: ToastPayload;
    showToast?: boolean;
}

const DEFAULT_STATE = {
    target: 50,
    annotationTypes: [],
    deadline: new Date(new Date().setMonth(new Date().getMonth() + 1)),
    priority: TaskPriority.Medium,
    name: "",
    instructions: "",
    stadiums: [],
    applications: [],
    sports: [],
    tags: [],
};

const TOAST_DATA: { [key: string]: ToastPayload } = {
    saved: { message: "Task saved successfully." },
    failed: {
        message: "Failed to save the task. Please try again.",
        type: "negative",
    },
};

interface TaskCreationFormProps {
    taskCreatedCallback: CallableFunction;
}

type Props = TaskCreationFormProps & ProvidedAppStore;

@inject("store")
@observer
export class MediaTaskCreationForm extends React.Component<Props, TaskCreationFormState> {
    store: MediaStore | undefined;

    constructor(props: Props) {
        super(props);
        this.store = props.store?.mediaStore;
        this.state = DEFAULT_STATE;
    }

    componentDidMount(): void {
        this.populateAnnotationTypesFieldWithAllOptions();
        this.store?.fetchFilterOptions();
    }

    componentDidUpdate(
        prevProps: Readonly<ProvidedAppStore>,
        prevState: Readonly<TaskCreationFormState>,
        snapshot?: any
    ): void {
        if (prevState.sports !== this.state.sports) this.store?.fetchFilterOptions();
    }

    populateAnnotationTypesFieldWithAllOptions() {
        this.store
            ?.fetchAnnotationTypes()
            .then(() => this.setState({ annotationTypes: this.store!.annotationTypes }));
    }

    isTaskSubmissionDisabled() {
        return (
            this.state.annotationTypes.length === 0 ||
            this.state.target === 0 ||
            this.state.name === ""
        );
    }

    async createTaskFromState() {
        if (!this.store) return;

        const savedSuccessfully = await this.store.submitTask(
            this.state.target,
            this.state.annotationTypes,
            this.state.deadline,
            this.state.priority,
            this.state.name,
            this.state.instructions,
            this.state.stadiums,
            this.state.applications,
            this.state.sports,
            this.state.tags,
            this.state.earliestUploadDate,
            this.state.latestUploadDate
        );
        const toastDataKey = savedSuccessfully ? "saved" : "failed";
        this.setState({ showToast: true, currentToastData: TOAST_DATA[toastDataKey] });

        if (savedSuccessfully) {
            this.setState({ ...this.state, ...DEFAULT_STATE });
            this.populateAnnotationTypesFieldWithAllOptions();
            this.props.taskCreatedCallback();
        }
    }

    render() {
        if (!this.store || this.store.annotationTypes.length === 0) return <Loader active />;

        const annotationTypeOptions = this.store.annotationTypes.map((val) => {
            return { text: `${val.category}, ${val.type}`, value: val.id };
        });

        return (
            <Container>
                <Header as="h3">Media Task Creation</Header>
                <Toast
                    showToast={this.state.showToast}
                    setShowToast={(show: boolean) => {
                        this.setState({ showToast: show });
                    }}
                    {...this.state.currentToastData}
                />
                <Form>
                    <Form.Group widths="equal">
                        <Form.Dropdown
                            clearable
                            multiple
                            search
                            selection
                            required
                            width={6}
                            label={"Annotation Types"}
                            options={annotationTypeOptions}
                            placeholder={"Annotation Types"}
                            onChange={(event, data) => {
                                const annotationTypes = (data.value as number[])
                                    .map((annotationTypeId) => {
                                        return this.store?.annotationTypes.find(
                                            (candidate) => candidate.id === annotationTypeId
                                        );
                                    })
                                    .filter((annotationType) => annotationType !== undefined);
                                this.setState({
                                    annotationTypes: annotationTypes as AnnotationType[],
                                });
                            }}
                            value={this.state.annotationTypes.map((option) => option.id)}
                        />
                        <Form.Input
                            required
                            width={2}
                            label={"Annotation Target"}
                            control={Input}
                            type={"number"}
                            onChange={(event, data) => {
                                this.setState({ target: parseInt(data.value) });
                            }}
                            value={this.state.target}
                        />
                        <Form.Field
                            width={2}
                            label="Deadline"
                            control={DayPickerInput}
                            placeholder={"Deadline"}
                            onDayChange={(value: any) => {
                                this.setState({ deadline: value });
                            }}
                            value={this.state.deadline}
                            style={{ width: "100%" }}
                        />
                    </Form.Group>
                    <Form.Group widths="equal">
                        <Form.Dropdown
                            selection
                            required
                            label={"Task Priority"}
                            options={Object.keys(TaskPriority).map((choice: string) => {
                                return { text: choice, value: choice };
                            })}
                            placeholder={"Task Priority"}
                            onChange={(event, data) => {
                                this.setState({ priority: data.value as TaskPriority });
                            }}
                            value={this.state.priority}
                        />
                        <Form.Input
                            required
                            label={"Name"}
                            control={Input}
                            placeholder={"Task Name"}
                            onChange={(event, data) => {
                                this.setState({ name: data.value });
                            }}
                            value={this.state.name}
                        />
                        <Form.Input
                            label={"Instructions"}
                            control={TextArea}
                            placeholder={"Task Instructions"}
                            onChange={(event, data) => {
                                this.setState({ instructions: data.value });
                            }}
                            value={this.state.instructions}
                            rows={1}
                        />
                    </Form.Group>
                    <Form.Group widths="equal">
                        <Form.Dropdown
                            clearable
                            multiple
                            search
                            selection
                            width={3}
                            label={"Sports"}
                            options={this.store.filterOptions.availableSports.map(
                                (choice: string) => {
                                    return { text: choice, value: choice };
                                }
                            )}
                            placeholder={"Sports"}
                            onChange={(event, data) => {
                                this.setState({ sports: data.value as string[] });
                            }}
                            value={this.state.sports}
                        />
                        <Form.Dropdown
                            clearable
                            multiple
                            search
                            selection
                            width={3}
                            label={"Stadiums"}
                            options={this.store.filterOptions.availableStadiums.map(
                                (choice: string) => {
                                    return { text: choice, value: choice };
                                }
                            )}
                            placeholder={"Stadiums"}
                            onChange={(event, data) => {
                                this.setState({ stadiums: data.value as string[] });
                            }}
                            value={this.state.stadiums}
                        />
                        <Form.Dropdown
                            clearable
                            multiple
                            search
                            selection
                            width={3}
                            label={"Applications"}
                            options={this.store.filterOptions.availableApplications.map(
                                (choice: string) => {
                                    return { text: choice, value: choice };
                                }
                            )}
                            placeholder={"Applications"}
                            onChange={(event, data) => {
                                this.setState({ applications: data.value as string[] });
                            }}
                            value={this.state.applications}
                        />
                        <Form.Dropdown
                            clearable
                            multiple
                            search
                            selection
                            width={5}
                            label={"Tags"}
                            options={this.store.filterOptions.availableTags.map(
                                (choice: string) => {
                                    return { text: choice, value: choice };
                                }
                            )}
                            placeholder={"Tags"}
                            onChange={(event, data) => {
                                this.setState({ tags: data.value as string[] });
                            }}
                            value={this.state.tags}
                        />
                    </Form.Group>
                    <Form.Group widths="equal">
                        <Form.Field
                            width={3}
                            label="Earliest Upload Date"
                            control={DayPickerInput}
                            onDayChange={(value: any) => {
                                this.setState({ earliestUploadDate: value });
                            }}
                            value={this.state.earliestUploadDate}
                            style={{ width: "100%" }}
                        />
                        <Form.Field
                            width={3}
                            label="Latest Upload Date"
                            control={DayPickerInput}
                            onDayChange={(value: any) => {
                                this.setState({ latestUploadDate: value });
                            }}
                            value={this.state.latestUploadDate}
                            style={{ width: "100%" }}
                        />
                        <Popup
                            content="Please fill in the annotation types, anotation target and task name."
                            disabled={!this.isTaskSubmissionDisabled()}
                            trigger={
                                <div>
                                    <Button
                                        positive
                                        disabled={this.isTaskSubmissionDisabled()}
                                        onClick={() => this.createTaskFromState()}
                                        style={{ height: "100%" }}
                                    >
                                        Create Task
                                    </Button>
                                </div>
                            }
                        />
                    </Form.Group>
                </Form>
            </Container>
        );
    }
}
