import { SemanticCOLORS } from "semantic-ui-react";
import { TaskState } from "../../models/Task";
const PERCENT_KEYS: { [state: string]: any } = {
    Incoming: { number: 0, colour: "grey" },
    InProgress: { number: 33, colour: "yellow" },
    AllAnnotated: { number: 66, colour: "orange" },
    Complete: { number: 100, colour: "green" },
    OnHold: { number: 50, colour: "red" },
    NoData: { number: 0, colour: "red" },
    Training: { number: 100, colour: "green" },
};

export function getProgressBarColourAndPercentage(state: TaskState) {
    return {
        percentage: PERCENT_KEYS[state]["number"],
        colour: PERCENT_KEYS[state]["colour"] as SemanticCOLORS,
    };
}
